import routes from '@/constants/routes';
import LoadingComponent from '@/Templates/components/LoadingCompoent';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ProxyLoginPage = () => {
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        let token = queryParams.get('token');
        const method = queryParams.get('method');
        const state = queryParams.get('state');
        switch (method) {
            case 'google': // Google 登入
            case 'facebook': // Facebook 登入
            case 'line': // Line 登入
            default:
                if (token) {
                    const decoded = decodeURIComponent(token);
                    localStorage.setItem('JWTToken', decoded);
                    const searchParams = new URLSearchParams();
                    searchParams.set('token', decoded);
                    if (method) {
                        searchParams.set('method', method);
                    }
                    if (state) {
                        searchParams.set('state', state);
                    }
                    window.location.replace(`${routes['LoginPage'].path}?${searchParams.toString()}`);
                } else {
                    window.location.replace(routes['LoginPage'].path);
                }
                break;
        }


    }, [location.search]);

    return (
        <LoadingComponent />
    );
}

export default ProxyLoginPage;
