import logo from '@/assets/Domigo-Logo-removebg-preview.png';
import { MerchantRoleEnum } from '@/constants/microShopIdentityRoleConstant';
import routes from '@/constants/routes';
import { GlobalContext } from '@/lib/contexts/GlobalContext';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import { DeviceType } from '@/Templates/enums/templateEnums';
import { LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined } from '@ant-design/icons';
import { Badge, Button, Col, Drawer, Flex, Image, Menu, MenuProps, MenuRef, Row, Select } from 'antd';
import Link from 'antd/es/typography/Link';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from "react-query";
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

type MenuItem = Required<MenuProps>['items'][number];

// 子组件：移动版 NavBar
const MobileNavBar = ({ visible, showDrawer, onClose, logo, isLogin, userInfoCount, renderAuthButtons, menuItems, current, handleClick, languaguesSelectorUI, divRef, menuRef, navigate, translate }) => (
    <div ref={divRef} className={`w-full flex items-center justify-start transition-all duration-300 pointer-events-auto`} style={{ backgroundColor: 'white', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.15)', height: 'fit-content', padding: '8px 0' }}>
        <div className="w-full flex items-center justify-between">
            <Button
                onClick={showDrawer}
                className={`transition-all duration-300 border-0`}
                style={{ height: '24px', fontSize: '24px' }}
            >
                {React.createElement(visible ? MenuUnfoldOutlined : MenuFoldOutlined)}
            </Button>
            <Image
                src={logo}
                alt="Logo"
                width={50}
                height={50}
                style={{ marginLeft: `-${50 / 2}px` }}
                onClick={() => {
                    navigate('/')
                    onClose()
                }}
                preview={false}
            />
            <div></div>
        </div>
        <Drawer
            placement="left"
            width="100%"
            onClose={onClose}
            open={visible}
            title={
                <Flex justify='end' style={{ width: '100%' }}>
                    {renderAuthButtons()}
                </Flex>
            }
        >
            <>
                <Menu
                    ref={menuRef}
                    key={uuidv4()}
                    onClick={handleClick}
                    className="w-full"
                    style={{ borderRightWidth: '0px' }}
                    mode="inline"
                    items={menuItems}
                    selectedKeys={[current]} // 设置选中的菜单项
                />
                <Flex justify='center' align='end'>
                    {languaguesSelectorUI}
                </Flex>
            </>
        </Drawer>
    </div>
);

// 子组件：桌面版 NavBar
const DesktopNavBar = ({ logo, isLogin, userInfoCount, renderAuthButtons, menuItems, current, handleClick, languaguesSelectorUI, menuRef, navigate, translate, onClose }) => (
    <Row className="border border-solid border-border-color border-1" justify="space-between" align="middle" style={{ backgroundColor: 'white' }}>
        <Col span={18}>
            <Menu
                ref={menuRef}
                key={'DesktopNavBar'}
                onClick={handleClick}
                className="w-full border-0"
                mode="horizontal"
                items={menuItems}
                selectedKeys={[current]} // 设置选中的菜单项
            />
        </Col>
        <Col span={6}>
            <Flex justify="end" align="center">
                {languaguesSelectorUI}
                {renderAuthButtons()}
            </Flex>
        </Col>
    </Row>
);

const NavBar: React.FC = React.memo(() => {
    const menuRef = useRef<MenuRef>(null);
    const divRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const location = useLocation();
    const {
        messageApi,
        removeMerchantIdAggregate,
        isSetMerchantIdAggregateLoading,
        hasMerchant,
        merchantId,
        isHideNavBar,
        isLogin,
        setIsLogin,
        setNavBarHeight,
        deviceType,
        setDeviceType,
        merchantPermissionRoles,
        isMerchantPermissionRolesLoading,
        switchBackOfficeMerchant,
    } = useContext(GlobalContext);
    const { translate, supportedLanguages, i18nLanguage, seti18nLanguage } = useContext(TranslationContext);
    const [visible, setVisible] = useState(false);
    const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
    const [current, setCurrent] = useState<string>(location.pathname);
    const [userInfoCount, setUserInfoCount] = useState<number>(0);

    const { mutate: getUserInfoCount } = useMutation(async () => Promise.resolve(), {
        onSuccess: (response) => {
            setUserInfoCount(0);
        }
    });

    useEffect(() => {
        getUserInfoCount();
    }, []);

    const handleClick = useCallback((e: any) => {
        setCurrent(e.key);
        onClose();
    }, []);

    const showDrawer = useCallback(() => {
        setVisible(true);
    }, []);

    const onClose = useCallback(() => {
        setVisible(false);
    }, []);

    const setNavBarHeightFunc = useCallback(() => {
        if ((deviceType === DeviceType.Mobile) && divRef.current?.offsetHeight === undefined) {
            return;
        }
        if ((deviceType !== DeviceType.Mobile) && menuRef.current?.menu?.list === undefined) {
            return;
        }
        const height = (deviceType !== DeviceType.Mobile) ? menuRef.current!.menu?.list.offsetHeight : divRef.current?.offsetHeight;
        if (height === undefined) {
            return;
        }
        setNavBarHeight(height + 10);
    }, [deviceType, setNavBarHeight]);


    const languaguesSelectorUI = useMemo(() => {
        const uI = supportedLanguages.map((lang) => (
            <Select.Option key={translate(lang)} value={lang} >
                {translate(lang)}
            </Select.Option>
        ));
        return (
            <Select
                style={{ width: 'auto' }}
                value={translate(i18nLanguage)}
                onChange={(value: string) => seti18nLanguage(value)}
            >
                {uI}
            </Select>
        );
    }, [supportedLanguages, i18nLanguage, translate, seti18nLanguage]);

    const Logout = useCallback(() => {
        localStorage.removeItem('JWTToken');
        removeMerchantIdAggregate();
        setIsLogin(false);
        messageApi.success(translate('Operation success'));
        navigate(`/login`);
        onClose();
    }, [removeMerchantIdAggregate, setIsLogin, messageApi, translate, navigate, onClose]);

    const Login = useCallback(() => {
        navigate(`/login`);
        onClose();
    }, [navigate, onClose]);

    const renderAuthButtons = useMemo(() => {
        return () => (
            isLogin ? (
                <Flex align="center">
                    <Badge count={userInfoCount} overflowCount={99} showZero={false}>
                        <UserOutlined
                            style={{ fontSize: '24px', cursor: 'pointer' }}
                            onClick={() => {
                                navigate('/user-info');
                                onClose();
                            }}
                        />
                    </Badge>
                    <Button onClick={Logout} style={{ marginLeft: '10px' }}>
                        <LogoutOutlined />
                        {translate('Logout')}
                    </Button>
                </Flex>
            ) : (
                <Button onClick={Login} style={{ marginLeft: '10px' }}>
                    <LogoutOutlined />
                    {translate('Login')}
                </Button>
            )
        );
    }, [isLogin, userInfoCount, navigate, onClose, Logout, Login, translate]);

    const memoizedNavigate = useCallback((path: string) => navigate(path), [navigate]);

    const memoizedMenuItems = useMemo(() => {
        return Object.keys(routes)
            .map((key) => {
                const route = routes[key];

                if (route.navbarIgnore) return null;

                if (route.merchantPermissionRoles && route.merchantPermissionRoles.length > 0) {
                    const hasRequiredPermission = route.merchantPermissionRoles.some((role) => merchantPermissionRoles?.includes(role)) || merchantPermissionRoles?.includes(MerchantRoleEnum.Owner);
                    if (!hasRequiredPermission) return null;
                }

                if (!route.ignoreMerchantSeleted && !hasMerchant) return null;

                return {
                    index: route.index || 0,
                    key: route.path,
                    label: (
                        <Link
                            onClick={() => memoizedNavigate(route.path)}
                            disabled={isMerchantPermissionRolesLoading || switchBackOfficeMerchant.isLoading}
                        >
                            {translate(key.replace('Page', ''))}
                        </Link>
                    ),
                };
            })
            .filter(Boolean) as MenuItem[];
    }, [hasMerchant, i18nLanguage, merchantPermissionRoles, isMerchantPermissionRolesLoading, isSetMerchantIdAggregateLoading, translate, memoizedNavigate]);



    useEffect(() => {
        setMenuItems(memoizedMenuItems);
    }, [memoizedMenuItems]);

    useEffect(() => {
        setNavBarHeightFunc();
    }, [deviceType, menuItems, setNavBarHeightFunc]);

    useEffect(() => {
        setCurrent(location.pathname); // 更新 current 状态为当前路径
    }, [location.pathname]);

    useEffect(() => {
        console.log('isMerchantPermissionRolesLoading', isMerchantPermissionRolesLoading);
    }, [isMerchantPermissionRolesLoading])

    if (!isLogin || isHideNavBar) {
        return null;
    }

    return (
        <div className={`fixed transition-all duration-300 w-full shadow-md z-50 border-0 ${deviceType === DeviceType.Mobile ? 'pointer-events-none' : ''}`}>
            {deviceType === DeviceType.Mobile ? (
                <MobileNavBar
                    visible={visible}
                    showDrawer={showDrawer}
                    onClose={onClose}
                    logo={logo}
                    isLogin={isLogin}
                    userInfoCount={userInfoCount}
                    renderAuthButtons={renderAuthButtons}
                    menuItems={menuItems}
                    current={current}
                    handleClick={handleClick}
                    languaguesSelectorUI={languaguesSelectorUI}
                    divRef={divRef}
                    menuRef={menuRef}
                    navigate={navigate}
                    translate={translate}
                />
            ) : (
                <DesktopNavBar
                    logo={logo}
                    isLogin={isLogin}
                    userInfoCount={userInfoCount}
                    renderAuthButtons={renderAuthButtons}
                    menuItems={menuItems}
                    current={current}
                    handleClick={handleClick}
                    languaguesSelectorUI={languaguesSelectorUI}
                    menuRef={menuRef}
                    navigate={navigate}
                    translate={translate}
                    onClose={onClose}
                />
            )}
        </div>
    );
});

export default React.memo(NavBar);
