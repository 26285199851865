export enum ThirdPartyInvoiceQueryStatusResponseType {
    /**
     * Update the status of the order and front end will refresh the page
     */
    Normal = 'Normal',

    /**
     * Redirect to the third party logistic website with Data
     */
    Redirect = 'Redirect',
}


export enum InvoviceOrderType {
    /** 一般 */
    Normal = 'Normal',

    /** 折讓 （退貨） */
    Allowance = 'Allowance',
}

export enum InvoiceOrderStatus {
    /** 處理中 */
    Pending = 'Pending',

    /** 已建立 */
    Created = 'Created',

    /** 取消 */
    Cancel = 'Cancel',

    /** 重新建立 */
    Reissued = 'Reissued',

    /**
     * 未知錯誤
     */
    UnknowError = 'UnknowError',
}