import { OrderStatus } from "@/enums/Enums";
import { IBatchOrderRequest, IGetBackOfficeOrderViewModelRequest, IManualAlterOrderRequest } from "@/interfaces/Requests/Requests";
import { IOrder } from "@/interfaces/Responses/Responses";
import { useBatchOrderCancelApi, useBatchOrderConfirmApi, useGetMerchantOrdersApi, useManualAlterOrderApi } from "@/lib/api/orders";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import { DeviceType } from "@/Templates/enums/templateEnums";
import { CopyOutlined, ExclamationCircleTwoTone, QuestionCircleTwoTone } from '@ant-design/icons';
import type { ActionType, ProColumns, ProFormInstance } from '@ant-design/pro-components';
import { ProFormDateRangePicker, ProFormSelect, ProFormText, ProTable } from '@ant-design/pro-components';
import { Button, Flex, Form, Input, Modal, Popover, Select, Space, Tooltip, Typography, message } from "antd";
import dayjs from 'dayjs';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import BatchOperation from './BatchOperation';
import OrderDetail, { IOrderDetailRef } from "./OrderDetail";


const { confirm } = Modal;
const { Text } = Typography;

const MySalesTab: React.FC = () => {
    const { translate, i18nLanguage } = useContext(TranslationContext);
    const { messageApi, deviceType } = useContext(GlobalContext);
    const [selectedOrder, setSelectedOrder] = useState<IOrder | undefined>(undefined);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentQuickFilter, setCurrentQuickFilter] = useState<string | null>(null);
    const [searchCollapsed, setSearchCollapsed] = useState<boolean>(true);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [selectedRows, setSelectedRows] = useState<IOrder[]>([]);
    const orderDetailRef = useRef<IOrderDetailRef>(null);
    const actionRef = useRef<ActionType>();
    const formRef = useRef<ProFormInstance>();
    const abortController = useRef(new AbortController());
    const location = useLocation();

    const [isManualModalVisible, setIsManualModalVisible] = useState(false);
    const [selectedManualOrder, setSelectedManualOrder] = useState<IOrder | undefined>(undefined);
    const [form] = Form.useForm();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const initialValues: Record<string, any> = {};

        if (searchParams.get('memberId')) initialValues.memberId = searchParams.get('memberId');
        if (searchParams.get('orderId')) initialValues.orderId = searchParams.get('orderId');
        if (searchParams.get('phoneNumber')) initialValues.phoneNumber = searchParams.get('phoneNumber');
        if (searchParams.get('email')) initialValues.email = searchParams.get('email');
        if (searchParams.get('orderStatus')) initialValues.orderStatus = searchParams.get('orderStatus')!.split(',');
        if (searchParams.get('itemName')) initialValues.itemName = searchParams.get('itemName');
        if (searchParams.get('specName')) initialValues.specName = searchParams.get('specName');
        if (searchParams.get('startDate') && searchParams.get('endDate')) {
            initialValues.createdTime = [
                dayjs(searchParams.get('startDate')),
                dayjs(searchParams.get('endDate'))
            ];
        }
        if (searchParams.get('pageSize')) initialValues.pageSize = parseInt(searchParams.get('pageSize')!);

        formRef.current?.setFieldsValue(initialValues);

        // 检查是否有高级搜索条件
        const hasAdvancedSearch = !!(searchParams.get('memberId') || searchParams.get('phoneNumber') ||
            searchParams.get('email') || searchParams.get('itemName') ||
            searchParams.get('specName') || (searchParams.get('startDate') && searchParams.get('endDate')));

        // 如果有高级搜索条件，设置 collapsed 为 false
        if (hasAdvancedSearch) {
            setSearchCollapsed(false);
        }

        // 提交表单
        formRef.current?.submit();
    }, [location.search]);

    const getStockRefillContent = () => {
        switch (i18nLanguage) {
            case 'zh_TW':
                return {
                    title: '庫存回補信息',
                    content: '商品回補會在訂單取消或退貨完成的階段回補貨品'
                };
            default:
            case 'en_US':
                return {
                    title: 'Stock Refill Information',
                    content: 'Stock will be refilled when the order is cancelled or the refund is completed'
                };
        }
    };

    const handleOrderSelected = useCallback((order: IOrder) => {
        setSelectedOrder(order);
        setIsModalVisible(true);
    }, []);

    const handleModalClose = useCallback(() => {
        setIsModalVisible(false);
        setSelectedOrder(undefined);
        orderDetailRef.current?.onClose();
    }, []);

    const handleQuickFilter = useCallback((filterName: string, statuses: OrderStatus[]) => {
        setCurrentQuickFilter(filterName);
        formRef.current?.setFieldsValue({ orderStatus: statuses });
        actionRef.current?.reload();
        formRef.current?.submit();
    }, []);

    const handleQuickDateRangeFilter = useCallback((days: number) => {
        const endDate = dayjs();
        const startDate = endDate.subtract(days, 'day');
        formRef.current?.setFieldsValue({
            createdTime: [startDate, endDate],
        });
        actionRef.current?.reload();
    }, []);

    const handleConfirm = useCallback((record: IOrder) => {
        confirm({
            title: translate('Confirm'),
            icon: <QuestionCircleTwoTone />,
            content: translate('Please confirm again'),
            okText: translate('Yes'),
            cancelText: translate('No'),
            onOk: async () => {
                const request: IBatchOrderRequest = {
                    orderIds: [record.id]
                };

                try {
                    const response = await useBatchOrderConfirmApi(request);
                    if (response.isSuccess) {
                        messageApi.success(translate('Order confirmed successfully'));
                        actionRef.current?.reload();
                    } else {
                        messageApi.error(translate(response.message || 'Failed to confirm order'));
                    }
                } catch (error) {
                    messageApi.error(translate('An error occurred while confirming the order'));
                }
            },
            onCancel() {
                console.log('Confirmation cancelled');
            },
        });
    }, [translate, messageApi]);

    const handleCancel = useCallback((record: IOrder) => {
        confirm({
            title: translate('Cancel'),
            icon: <ExclamationCircleTwoTone twoToneColor="red" />,
            content: translate('Please confirm again'),
            okText: translate('Yes'),
            cancelText: translate('No'),
            onOk: async () => {
                const request: IBatchOrderRequest = {
                    orderIds: [record.id]
                };

                try {
                    const response = await useBatchOrderCancelApi(request);
                    if (response.isSuccess) {
                        messageApi.success(translate('Order cancel successfully'));
                        actionRef.current?.reload();
                    } else {
                        messageApi.error(translate(response.message || 'Failed to cancel order'));
                    }
                } catch (error) {
                    messageApi.error(translate('An error occurred while cancel the order'));
                }
            },
            onCancel() {
                console.log('Confirmation cancelled');
            },
        });
    }, [translate, messageApi]);

    const handleShip = useCallback((record: IOrder) => {
        messageApi.success('Operation success');
    }, [messageApi]);

    const handleRefund = useCallback((record: IOrder) => {
        messageApi.success('Operation success');
    }, [messageApi]);

    const handleManual = useCallback((record: IOrder) => {
        setSelectedManualOrder(record);
        setIsManualModalVisible(true);
        form.setFieldsValue({
            newStatus: undefined,
            reason: ''
        });
    }, [form]);

    const handleManualConfirm = useCallback(() => {
        form.validateFields().then(values => {
            const proceedWithOperation = () => {
                confirm({
                    title: translate('Confirm'),
                    icon: <QuestionCircleTwoTone />,
                    content: translate('This action cannot be undone'),
                    async onOk() {
                        const request: IManualAlterOrderRequest = {
                            orderId: selectedManualOrder!.id,
                            status: values.newStatus,
                            reason: values.reason,
                            isPaid: values.isPaid,
                            hasRefilledStockAmount: values.hasRefilledStockAmount,
                        };

                        const response = await useManualAlterOrderApi(request);
                        if (response.isSuccess) {
                            messageApi.success(translate('Operation success'));
                            setIsManualModalVisible(false);
                            form.resetFields();
                            setSelectedManualOrder(undefined);
                            actionRef.current?.reload();
                        } else {
                            messageApi.error(translate(response.message || 'Operation failed'));
                        }
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                });
            };

            proceedWithOperation();
        }).catch(info => {
            console.log('Validate Failed:', info);
        });
    }, [form, selectedManualOrder, translate, useManualAlterOrderApi, messageApi]);

    const copyToClipboard = useCallback((text: string) => {
        navigator.clipboard.writeText(text).then(() => {
            message.success(translate('Copied to clipboard'));
        }).catch(() => {
            message.error(translate('Operation failed'));
        });
    }, [message, translate]);

    const columns: ProColumns<IOrder>[] = useMemo(() => [
        {
            title: translate('Order ID'),
            dataIndex: 'id',
            width: 200,
            sorter: (a, b) => a.id.toString().localeCompare(b.id.toString()),
            render: (_, record) => (
                <Space>
                    <Button type="link" onClick={() => handleOrderSelected(record)}>
                        {record.id.toString()}
                    </Button>
                    <Tooltip title={translate('Copy')}>
                        <Button
                            type="link"
                            icon={<CopyOutlined />}
                            onClick={() => copyToClipboard(record.id.toString())}
                        />
                    </Tooltip>
                </Space>
            ),
        },
        {
            title: translate('Status'),
            dataIndex: 'status',
            width: 150,
            sorter: (a, b) => a.status.localeCompare(b.status),
            valueType: 'select',
            valueEnum: Object.fromEntries(
                Object.entries(OrderStatus).map(([key, value]) => [key, { text: translate(key) }])
            ),
            renderFormItem: () => (
                <ProFormSelect
                    name="orderStatus"
                    mode="multiple"
                    placeholder={translate('Status')}
                    options={Object.entries(OrderStatus).map(([key, value]) => ({
                        label: translate(key),
                        value: key
                    }))}
                    fieldProps={{
                        onChange: () => setCurrentQuickFilter(null)
                    }}
                />
            ),
            render: (_, record) => translate(record.status),
        },
        {
            title: translate('CreatedTime'),
            dataIndex: 'createdTime',
            sorter: (a, b) => dayjs(a.createdTime).unix() - dayjs(b.createdTime).unix(),
            valueType: 'dateRange',
            search: {
                transform: (value) => ({
                    startDate: value[0],
                    endDate: value[1],
                }),
            },
            renderFormItem: (_, { type, defaultRender, ...rest }) => {
                return (
                    <>
                        <ProFormDateRangePicker
                            name="createdTime"
                            {...rest}
                        />
                        <Flex justify="start" style={{ marginTop: 8 }}>
                            <Button size="small" onClick={() => handleQuickDateRangeFilter(7)}>
                                {translate('Last 7 days')}
                            </Button>
                            <Button size="small" onClick={() => handleQuickDateRangeFilter(30)}>
                                {translate('Last 30 days')}
                            </Button>
                        </Flex>
                    </>
                );
            },
            render: (_, record) => dayjs(record.createdTime).format('YYYY-MM-DD HH:mm:ss'),
        },
        {
            title: translate('UpdatedTime'),
            dataIndex: 'updatedTime',
            sorter: (a, b) => dayjs(a.alterDate).unix() - dayjs(b.alterDate).unix(),
            render: (_, record) => dayjs(record.alterDate).format('YYYY-MM-DD HH:mm:ss'),
            search: false
        },
        {
            title: translate('Member ID'),
            dataIndex: 'memberId',
            hideInTable: true,
            search: {
                transform: (value) => ({ memberId: value }),
            },
            renderFormItem: () => (
                <ProFormText
                    name="memberId"
                    placeholder={translate('Member ID')}
                />
            ),
        },
        {
            title: translate('Phone Number'),
            dataIndex: 'phoneNumber',
            hideInTable: true,
            search: {
                transform: (value) => ({ phoneNumber: value }),
            },
            renderFormItem: () => (
                <ProFormText
                    name="phoneNumber"
                    placeholder={translate('Phone Number')}
                />
            ),
        },
        {
            title: translate('Email'),
            dataIndex: 'email',
            hideInTable: true,
            search: {
                transform: (value) => ({ email: value }),
            },
            renderFormItem: () => (
                <ProFormText
                    name="email"
                    placeholder={translate('Email')}
                />
            ),
        },
        {
            title: translate('ItemName'),
            dataIndex: 'itemName',
            hideInTable: true,
            search: {
                transform: (value) => ({ itemName: value }),
            },
            renderFormItem: () => (
                <ProFormText
                    name="itemName"
                    placeholder={translate('ItemName')}
                />
            ),
        },
        {
            title: translate('SpecName'),
            dataIndex: 'specName',
            hideInTable: true,
            search: {
                transform: (value) => ({ specName: value }),
            },
            renderFormItem: () => (
                <ProFormText
                    name="specName"
                    placeholder={translate('SpecName')}
                />
            ),
        },
        {
            title: translate('Operation'),
            key: 'operations',
            valueType: 'option',
            render: (_, record) => {
                const operations = [
                    <Button type="link" key="Manual" onClick={() => handleManual(record)}>
                        {translate('Manual')}
                    </Button>,
                ];

                if (record.status === OrderStatus.WaitForMerchantConfirm) {
                    //add cancel button
                    operations.unshift(
                        <Button danger type="link" key="cancel" onClick={() => handleCancel(record)}>
                            {translate('Cancel')}
                        </Button>
                    );
                    //add confrim button
                    operations.unshift(
                        <Button type="link" key="confirm" onClick={() => handleConfirm(record)}>
                            {translate('Confirm')}
                        </Button>
                    );

                }
                else if (record.status === OrderStatus.WaitForShipment) {
                    operations.unshift(
                        <Button type="link" key="ship" onClick={() => handleShip(record)}>
                            {translate('Ship')}
                        </Button>
                    );
                }
                else if (record.status === OrderStatus.WaitForMerchantConfirmRefundApplication) {
                    operations.unshift(
                        <Button type="link" key="refund" onClick={() => handleRefund(record)}>
                            {translate('Confirm Refund Application')}
                        </Button>
                    );
                }
                else if (record.status === OrderStatus.WaitForMerchantRefundAmount) {
                    operations.unshift(
                        <Button type="link" key="refund" onClick={() => handleRefund(record)}>
                            {translate('Refund')}
                        </Button>
                    );
                }
                return (
                    <Flex justify="space-between">
                        {operations}
                    </Flex>
                );
            },
        },
    ], [handleOrderSelected, copyToClipboard, handleManual, handleConfirm, handleShip, handleRefund, translate, setCurrentQuickFilter, handleQuickDateRangeFilter]);

    useEffect(() => {
        abortController.current = new AbortController();
        return () => {
            abortController.current.abort();
        }
    }, [])

    return (
        <div style={{ padding: '20px' }}>
            <Space style={{ marginBottom: 16 }} hidden={deviceType === DeviceType.Mobile} wrap>
                <Button onClick={() => handleQuickFilter('全部', [])} type={currentQuickFilter === '全部' ? 'primary' : 'default'}>{translate('All')}</Button>
                <Button onClick={() => handleQuickFilter('尚未付款', [OrderStatus.WaitForPaid])} type={currentQuickFilter === '尚未付款' ? 'primary' : 'default'}>{translate('WaitForPaid')}</Button>
                <Button onClick={() => handleQuickFilter('待確認', [OrderStatus.Paid, OrderStatus.WaitForMerchantConfirm])} type={currentQuickFilter === '待確認' ? 'primary' : 'default'}>{translate('WaitForMerchantConfirm')}</Button>
                <Button onClick={() => handleQuickFilter('待出貨', [OrderStatus.WaitForShipment])} type={currentQuickFilter === '待出貨' ? 'primary' : 'default'}>{translate('WaitForShipment')}</Button>
                <Button onClick={() => handleQuickFilter('運送中', [OrderStatus.DuringShipment, OrderStatus.DuringReturnShipment, OrderStatus.WaitForBuyerPickup, OrderStatus.WaitForMerchantPickupRefund])} type={currentQuickFilter === '運送中' ? 'primary' : 'default'}>{translate('DuringShipment')}</Button>
                <Button onClick={() => handleQuickFilter('已完成', [OrderStatus.Done])} type={currentQuickFilter === '已完成' ? 'primary' : 'default'}>{translate('Done')}</Button>
                <Button onClick={() => handleQuickFilter('不成立', [OrderStatus.Cancel])} type={currentQuickFilter === '不成立' ? 'primary' : 'default'}>{translate('Cancel')}</Button>
                <Button onClick={() => handleQuickFilter('退款/退貨', [OrderStatus.BuyerApplyRefund, OrderStatus.WaitForMerchantConfirmRefundApplication, OrderStatus.WaitForMerchantRefundAmount, OrderStatus.DuringReturnShipment])} type={currentQuickFilter === '退款/退貨' ? 'primary' : 'default'}>{translate('Return/Refund')}</Button>
                <Button onClick={() => handleQuickFilter('運送失敗', [OrderStatus.ShipmentDispute, OrderStatus.ReturnShipmentDispute])} type={currentQuickFilter === '運送失敗' ? 'primary' : 'default'}>{translate('ReturnShipmentDispute')}</Button>
                <Button onClick={() => handleQuickFilter('退款爭議', [OrderStatus.RefundUnderDispute])} type={currentQuickFilter === '已取消' ? 'primary' : 'default'}>{translate('RefundUnderDispute')}</Button>
            </Space>
            <ProTable<IOrder>
                columns={columns}
                actionRef={actionRef}
                formRef={formRef}
                cardBordered
                scroll={{ x: 'max-content' }}
                rowSelection={{
                    preserveSelectedRowKeys: true,
                    selectedRowKeys,
                    onChange: (keys, rows) => {
                        setSelectedRowKeys(keys);
                        setSelectedRows(rows);
                    },
                }}
                search={{
                    labelWidth: 'auto',
                    collapsed: searchCollapsed,
                    onCollapse: setSearchCollapsed,
                    span: {
                        xs: 24,
                        sm: 24,
                        md: 12,
                        lg: 8,
                        xl: 6,
                        xxl: 6,
                    },
                    optionRender: (searchConfig, formProps, dom) => [...dom.reverse()],
                }}
                tableLayout="fixed"
                rowKey={(record) => record.id.toString()}
                pagination={{
                    showQuickJumper: true,
                    showSizeChanger: true,
                    defaultPageSize: 10,
                }}
                request={async (params, sorter, filter) => {
                    const queryParams: IGetBackOfficeOrderViewModelRequest = {
                        page: params.current,
                        pageSize: params.pageSize,
                        startDate: params.startDate ? dayjs(params.startDate).toDate() : undefined,
                        endDate: params.endDate ? dayjs(params.endDate).toDate() : undefined,
                        memberId: params.memberId ? params.memberId : undefined,
                        orderId: params.orderId ? params.orderId : undefined,
                        phoneNumber: params.phoneNumber,
                        email: params.email,
                        orderStatus: params.orderStatus as OrderStatus[],
                        itemName: params.itemName,
                        specName: params.specName,
                    };

                    const response = await useGetMerchantOrdersApi(queryParams, abortController.current.signal);
                    if (response.isSuccess) {
                        const data = response.result?.data || [];
                        const preservedData = data.map(item => {
                            if (selectedRowKeys.includes(item.id.toString())) {
                                return { ...item, _checked: true };
                            }
                            return item;
                        });

                        return {
                            data: preservedData,
                            success: true,
                            total: response.result?.totalCount || 0,
                        };
                    } else {
                        messageApi.error(translate(response.message || 'Operation failed'));
                        return {
                            data: [],
                            success: false,
                            total: 0,
                        };
                    }
                }}
                columnsState={{
                    persistenceKey: 'my-sales-table',
                    persistenceType: 'localStorage',
                    defaultValue: {
                        memberId: { show: false },
                        phoneNumber: { show: false },
                        email: { show: false },
                        itemName: { show: false },
                        specName: { show: false },
                        updatedTime: { show: false, disable: false },
                    },
                }}
                dateFormatter="string"
                onReset={() => {
                    setCurrentQuickFilter('全部');
                    setSearchCollapsed(true);
                }}
                toolBarRender={() => [
                    <BatchOperation
                        selectedRows={selectedRows}
                        disabled={selectedRowKeys.length === 0}
                        onSuccess={() => {
                            setSelectedRowKeys([]);
                            setSelectedRows([]);
                            actionRef.current?.reload();
                        }}
                    />
                ]}
            />
            <Modal
                open={isModalVisible}
                onCancel={handleModalClose}
                footer={null}
                width={800}
                destroyOnClose={true}
                afterOpenChange={(visible) => {
                    if (visible) {
                        orderDetailRef.current?.onOpen();
                    }
                }}
            >
                {
                    selectedOrder &&
                    <OrderDetail
                        ref={orderDetailRef}
                        order={selectedOrder}
                    />
                }
            </Modal>
            <Modal
                title={translate('Manual Operation')}
                open={isManualModalVisible}
                onOk={handleManualConfirm}
                onCancel={() => {
                    setIsManualModalVisible(false);
                    form.resetFields();
                    setSelectedManualOrder(undefined);
                }}
                afterOpenChange={(visible) => {
                    if (visible && selectedManualOrder) {
                        form.setFieldsValue({
                            newStatus: selectedManualOrder.status,
                            isPaid: selectedManualOrder.isPaid,
                            hasRefilledStockAmount: selectedManualOrder.hasRefilledStockAmount,
                            reason: ''  // 清空reason字段
                        });
                    }
                }}
            >
                {selectedManualOrder && (
                    <Form form={form} layout="vertical">
                        <Space direction="vertical" style={{ width: '100%', marginBottom: 16 }}>
                            <Text>{translate('Order ID')}: {selectedManualOrder.id.toString()}</Text>
                            <Text>{translate('Current Status')}: {translate(selectedManualOrder.status)}</Text>
                            <Text>{translate('Current Is Paid')}: {selectedManualOrder.isPaid ? translate('Yes') : translate('No')}</Text>
                            <Text>{translate('Current Has Refilled Stock Amount')}: {selectedManualOrder.hasRefilledStockAmount ? translate('Yes') : translate('No')}</Text>
                        </Space>
                        <Form.Item
                            name="newStatus"
                            label={translate('New Order Status')}
                            rules={[{ required: true, message: translate('Please select new status') }]}
                        >
                            <Select
                                showSearch
                                placeholder={translate('Select New Order Status')}
                                filterOption={(input, option) => {
                                    if (option && typeof option.label === 'string') {
                                        return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                    }
                                    return false;
                                }}
                            >
                                {Object.entries(OrderStatus)
                                    .filter(([key, value]) => value !== OrderStatus.Paid && value !== OrderStatus.Unknow)
                                    .map(([key, value]) => (
                                        <Select.Option key={value} value={value} label={translate(key)}>
                                            {translate(key)}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="isPaid"
                            label={translate('New Is Paid')}
                        >
                            <Select>
                                <Select.Option value={true}>{translate('Yes')}</Select.Option>
                                <Select.Option value={false}>{translate('No')}</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="hasRefilledStockAmount"
                            label={
                                <Space>
                                    {translate('New Has Refilled Stock Amount')}
                                    <Popover
                                        content={getStockRefillContent().content}
                                        title={getStockRefillContent().title}
                                    >
                                        <QuestionCircleTwoTone style={{ cursor: 'pointer' }} />
                                    </Popover>
                                </Space>
                            }
                        >
                            <Select>
                                <Select.Option value={true}>{translate('Yes')}</Select.Option>
                                <Select.Option value={false}>{translate('No')}</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="reason"
                            label={translate('Reason for Manual Operation')}
                            rules={[{ required: true, message: translate('Is required') }]}
                        >
                            <Input.TextArea
                                rows={4}
                                placeholder={translate('Reason')}
                            />
                        </Form.Item>
                    </Form>
                )}
            </Modal>
        </div>
    );
};

export default MySalesTab;
