import { CurrencyCode } from '@/enums/CurrecnyCode';
import { useGetItemsByMerchantApi, useGetItemSpecsApi } from '@/lib/api/items';
import { useGetQueryOptionsByMerchantIdApi } from '@/lib/api/portals';
import { GlobalContext } from '@/lib/contexts/GlobalContext';
import LoadingComponent from '@/Templates/components/LoadingCompoent';
import {
    DeviceType,
    MerchantPortalStyleSettingType,
    MerchantPortalStyleShopComponents,
    MerchantPortalStyleShopPages
} from '@/Templates/enums/templateEnums';
import { ObjectComparisonHelper } from '@/Templates/helpers/ObjectComparisonHelper';
import {
    IItemSpec,
    IItemViewModel,
    IPaginationResponse,
    IPortalMerchantThirdPartyLogisticSettingViewModel,
    IPortalMerchantThirdPartyPaymentSettingViewModel,
    IPortalQueryItemByMerchantRequest,
    ISearchQueryState,
    IShoppingCartItemViewModel,
    IShoppingCartViewModel,
    ITempShoppingCartViewModel
} from '@/Templates/interfaces/templatesInterfaces';
import { useCheckOut } from '@/Templates/Shop/Pages/ShoppingCartPage/Contexts/ICheckOutContext';
import {
    ItemCardTemplateProps,
    ItemDetailPageTemplateProps,
    NavBarTemplateProps,
    PagesTemplateProps,
    ShoppingCartPageTemplateProps,
    TemplateProps,
} from '@/Templates/TemplateProps';
import {
    LaptopOutlined,
    MobileOutlined,
    TabletOutlined,
} from '@ant-design/icons';
import { Button, Col, Drawer, Empty, Flex, Form, List, Row, Select, Tabs, Typography } from 'antd';
import React, {
    forwardRef,
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from 'react';
import styled from 'styled-components';
import { PortalSettingPageContext } from '../Contexts/PortalSettingPageContext';
import StyleConfigSelector from './StyleConfigSelector';

const { Title, Text } = Typography;

export interface ShopPreviewProps extends TemplateProps {
    selectedComponentStyle: number;
    selectedPageStyle: number;
    onComponentConfigFieldsChange: (
        fields: Array<{ key: string; label: string; type: string; defaultValue: string }>
    ) => void;
    onPageConfigFieldsChange: (
        fields: Array<{ key: string; label: string; type: string; defaultValue: string }>
    ) => void;
}

export interface IShopStyleSelectorRef {
    onRefresh: () => void;
}

// 預設測試商品
const defaultTestItem = (translate: (key: string) => string): IItemViewModel => {
    return {
        id: BigInt(1),
        name: `${translate('Test')} ${translate('Item')} - 1`,
        title: `${translate('Test')} ${translate('Item')} ${translate('Title')}`,
        briefly: `${translate('Test')} ${translate('Item')} ${translate('Briefly')}`,
        description: `${translate('Test')} ${translate('Item')} ${translate('Description')}`,
        createdDate: new Date().toISOString(),
        alterDate: new Date().toISOString(),
        isDelete: false,
        itemConverImagePaths: [
            'https://cdn.domigo-digital.com/system/TestItem.png',
            'https://cdn.domigo-digital.com/system/BackgroundImage1.jpg',
            'https://cdn.domigo-digital.com/system/None.png',
        ],
        itemTags: [
            {
                id: BigInt(1),
                merchantId: BigInt(1),
                name: '测试标签',
                isDeleted: false,
            },
        ],
        minPrice: 99.99,
        maxPrice: 199.99,
        isOnShelf: true,
        isSoldOut: false
    }
}
// 預設測試規格
const defaultTestItemSpecs = (translate: (key: string) => string): IItemSpec[] => [
    {
        id: BigInt(1),
        name: `${translate('Test')} ${translate('Spec')} ${translate('Unmanaged')}`,
        customizeItemNo: '',
        currency: CurrencyCode.TWD,
        sellPrice: 100,
        fixedPrice: 100,
        isManageStockAmount: false,
        stockAmount: 0,
        length: 0,
        width: 0,
        height: 0,
        weight: 0,
        note: '',
        customizeManufacturerNo: '',
        createdDate: '',
        alterDate: '',
        isDeleted: false,
        sortIndex: 0,
    },
    {
        id: BigInt(2),
        name: `${translate('Test')} ${translate('Spec')} ${translate('Normal')}`,
        customizeItemNo: '',
        currency: CurrencyCode.TWD,
        sellPrice: 200,
        fixedPrice: 200,
        isManageStockAmount: true,
        stockAmount: 100,
        length: 0,
        width: 0,
        height: 0,
        weight: 0,
        note: '',
        customizeManufacturerNo: '',
        createdDate: '',
        alterDate: '',
        isDeleted: false,
        sortIndex: 1,
    },
    {
        id: BigInt(3),
        name: `${translate('Test')} ${translate('Spec')} ${translate('SoldOut')}`,
        customizeItemNo: '',
        currency: CurrencyCode.TWD,
        sellPrice: 200,
        fixedPrice: 200,
        isManageStockAmount: true,
        stockAmount: 0,
        length: 0,
        width: 0,
        height: 0,
        weight: 0,
        note: '',
        customizeManufacturerNo: '',
        createdDate: '',
        alterDate: '',
        isDeleted: false,
        sortIndex: 2,
    }
];

interface StyleState {
    style: number;
    configFields: Array<any>;
    styleConfig: Record<string, string>;
}

const PreviewContainer = styled.div<{ containerwidth: number, previewwidth: string; previewdevicetype: DeviceType, windowdevicetype: DeviceType }>`
  border: 1px solid #ccc;
  overflow-y: scroll;
  overflow-x: hidden; 
  display: flex;
  align-items: flex-start; // 改為靠上對齊
  justify-content: center;
  width: ${props => `${props.previewwidth}`};
  max-width: ${props => props.windowdevicetype === DeviceType.Desktop ? '100%' : '90%'};
  margin: 0 auto;
  position: relative;
  height: 75vh; // 設置固定高度
  max-height: 80vh;

  & > div {
    width: ${props => props.previewwidth};
    height: ${props => props.previewdevicetype === DeviceType.Mobile ? '100%' : 'auto'};
    transform-origin: top center; // 改為從頂部中縮放
    transition: transform 0.3s ease;
    position: absolute;
    top: 0; // 靠上對齊
    left: 50%;
  }

  ${props => {
        const previewWidth = props.previewwidth;
        const previewWidthNumber = parseInt(previewWidth.replace('px', ''));
        // 計算縮放比例
        const scale = Math.min(props.containerwidth / previewWidthNumber, 1);
        return `
      & > div {
        transform: translateX(-50%) scale(${scale});
      }
    `;
    }}

  /* 定義自定義容器查詢 */
  container-type: inline-size;
  container-name: preview-container;

  /* 添加內部容器以確保內容可以水平滾動 */
  & > div {
    min-width: 100%;
    width: ${props => props.previewwidth};
  }

  /* 自定義滾動條樣式 */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const ShopPreview = forwardRef<IShopStyleSelectorRef, ShopPreviewProps>(
    ({
        merchantId,
        messageApi,
        translate
    }, ref) => {
        const [seleceTestDataDrawerVisible, setSeleceTestDataDrawerVisible] = useState(false);
        const [merchantItems, setMerchantItems] = useState<IItemViewModel[]>([]);
        const [testItem, setTestItem] = useState<IItemViewModel>(defaultTestItem(translate));
        const [testItemSpecs, setTestItemSpecs] = useState<IItemSpec[]>(defaultTestItemSpecs(translate));
        const { abortController } = useContext(PortalSettingPageContext);
        var queryRequest: IPortalQueryItemByMerchantRequest = {
            merchantId,
            categoryIds: [],
            isOnShelf: true
        };
        const [testPaginationItems, setTestPaginationItems] = useState<IPaginationResponse<IItemViewModel[]>>({
            data: [testItem],
            totalCount: 1,
            totalPage: 1
        });
        const [testSearchQueryState, setTestSearchQueryState] = useState<ISearchQueryState>({
            options: {
                isSuccess: true,
                result: {
                    itemTags: [],
                    categories: [],
                }
            },
            form: undefined,
            setRequest: function (request: any): void {

            },
            search: function (request: any): void {

            },
            loading: false,
            onFormValuesChange: (changedValues: any, allValues: any) => {
                queryRequest = {
                    merchantId: merchantId,
                    fuzzingName: allValues.fuzzingName,
                    categoryIds: allValues.categoryIds?.filter(id => !isNaN(Number(id))).map(id => BigInt(id)) || [],
                    extraItemTagIds: allValues.itemTagIds?.filter(id => !isNaN(Number(id))).map(id => BigInt(id)) || [],
                    extraItemIds: allValues.extraItemIds?.filter(id => !isNaN(Number(id))).map(id => BigInt(id)) || [],
                };
            },
            onSearch: function (): void {
                useGetItemsByMerchantApi(queryRequest).then((res) => {
                    if (res.result) {
                        if (testPaginationItems.data.length !== res.result.data.length + 1) {
                            var newData = [...res.result.data, defaultTestItem(translate)];
                            setTestPaginationItems({
                                data: newData,
                                totalCount: res.result.totalCount + 1,
                                totalPage: res.result.totalPage,
                            });
                        }
                    }
                });
            },
            isLoading: false
        });
        const {
            merchantPortalOptionSetting,
            merchantPortalStyleSettings,
            isMerchantPortalStyleSettingsLoading,
            deviceType: windowdevicetype,
        } = useContext(GlobalContext);
        const {
            selectedItems,
            setSelectedItems,
            currentStep,
            setCurrentStep,
            userWantReservePay,
            setSelectedLogistic,
            setUserWantReservePay,
            logisticValues,
            setLogisticValues,
            receiverInfos,
            setReceiverInfos,
            nextStep,
            prevStep,
            handleSelectAll,
            handleSelectItem
        } = useCheckOut();
        const [previewdevicetype, setPreviewDeviceType] = useState<DeviceType>(
            DeviceType.Desktop
        );
        const previewContainerRef = useRef<HTMLDivElement>(null);
        const [containerwidth, setContainerWidth] = useState<number>(0);
        const [ComponentTemplateComponent, setComponentTemplateComponent] =
            useState<React.ComponentType<TemplateProps> | null>(null);
        const [PageTemplateComponent, setPageTemplateComponent] =
            useState<React.ComponentType<PagesTemplateProps> | null>(null);
        const [mockComponentProps, setMockComponentProps] = useState<any>(null);
        const [mockPageProps, setMockPageProps] = useState<any>(null);
        const [selectedPageType, setSelectedPageType] =
            useState<MerchantPortalStyleShopPages>(
                MerchantPortalStyleShopPages.LandingPage
            );
        const [selectedComponentType, setSelectedComponentType] =
            useState<MerchantPortalStyleShopComponents>(
                MerchantPortalStyleShopComponents.NavBar
            );
        const [refreshComponentCount, setRefreshComponentCount] = useState(0);
        const [refreshPageCount, setRefreshPageCount] = useState(0);
        const [activeTab, setActiveTab] = useState<MerchantPortalStyleSettingType>(
            MerchantPortalStyleSettingType.Components
        );
        const [styleState, setStyleState] = useState<{
            component: StyleState;
            page: StyleState;
        }>({
            component: {
                style: 0,
                configFields: [],
                styleConfig: {},
            },
            page: {
                style: 0,
                configFields: [],
                styleConfig: {},
            },
        });
        const [selectedItemSpec, setSelectedItemSpec] = useState<IItemSpec | null>(
            testItemSpecs[0]
        );
        const [testshoppingCartData, setTestshoppingCartData] = useState<IShoppingCartViewModel | ITempShoppingCartViewModel | undefined>(undefined);
        const [purchaseQuantity, setPurchaseQuantity] = useState<number>(0);
        const [form] = Form.useForm();
        const [selectedItemId, setSelectedItemId] = useState<string>('default');

        const loadComponentTemplate = useCallback(async () => {
            try {
                const componentModule = await import(
                    `@/Templates/Shop/Components/${selectedComponentType}/Template_${styleState.component.style}.tsx`
                );
                setComponentTemplateComponent(() => componentModule.default);

                let newFields = [];
                if (componentModule.getConfigFields) {
                    newFields = componentModule.getConfigFields();
                }
                const merchantAlreadyFilledConfigs = merchantPortalStyleSettings.find(
                    (x) =>
                        x.type === MerchantPortalStyleSettingType.Components &&
                        x.name === selectedComponentType &&
                        x.style === styleState.component.style
                );
                setStyleState((prevState) => ({
                    ...prevState,
                    component: {
                        ...prevState.component,
                        configFields: newFields,
                        styleConfig: merchantAlreadyFilledConfigs?.styleSetting || {},
                    },
                }));
                console.log(styleState.component.styleConfig);
                setRefreshComponentCount((prev) => prev + 1);
            } catch (error) {
                console.error('加载组件模板失败:', error);
                setComponentTemplateComponent(null);
                setStyleState((prevState) => ({
                    ...prevState,
                    component: {
                        ...prevState.component,
                        configFields: [],
                        styleConfig: {},
                    },
                }));
            }
        }, [
            selectedComponentType,
            styleState.component.style,
            merchantPortalStyleSettings,
        ]);

        const loadPageTemplate = useCallback(async () => {
            try {
                const pageModule = await import(
                    `@/Templates/Shop/Pages/${selectedPageType}/Template_${styleState.page.style}.tsx`
                );
                setPageTemplateComponent(() => pageModule.default);

                let newFields = [];
                if (pageModule.getConfigFields) {
                    newFields = pageModule.getConfigFields();
                }

                const merchantAlreadyFilledConfigs = merchantPortalStyleSettings.find(
                    (x) =>
                        x.type === MerchantPortalStyleSettingType.Pages &&
                        x.name === selectedPageType &&
                        x.style === styleState.page.style
                );

                setStyleState((prevState) => ({
                    ...prevState,
                    page: {
                        ...prevState.page,
                        configFields: newFields,
                        styleConfig: merchantAlreadyFilledConfigs?.styleSetting || {},
                    },
                }));
                setRefreshPageCount((prev) => prev + 1);
            } catch (error) {
                console.error('加载页面模板失败:', error);
                setPageTemplateComponent(null);
                setStyleState((prevState) => ({
                    ...prevState,
                    page: {
                        ...prevState.page,
                        configFields: [],
                        styleConfig: {},
                    },
                }));
            }
        }, [
            selectedPageType,
            styleState.page.style,
            merchantPortalStyleSettings,
        ]);

        const loadMockComponentProps = useCallback(() => {
            console.log('refreshComponentCount', refreshComponentCount);
            try {
                console.log('selectedComponentType', selectedComponentType);
                switch (selectedComponentType) {
                    case MerchantPortalStyleShopComponents.NavBar:
                        const navBarProps: NavBarTemplateProps = {
                            merchantId,
                            BASE_PATH: '/base-path',
                            isHideNavBar: false,
                            merchantPortalOptionSetting: merchantPortalOptionSetting,
                            navBarHeight: 60,
                            deviceType: previewdevicetype,
                            shoppingCartItemCount: 0,
                            isLogin: false,
                            translate,
                            isPreviewMode: true,
                            portalOptionSettingViewModel: merchantPortalOptionSetting,
                            portalStyleSettings: merchantPortalStyleSettings,
                            config: styleState.component.styleConfig,
                            messageApi,
                            previewContainer: previewContainerRef.current
                                ? previewContainerRef.current
                                : undefined,
                        };
                        setMockComponentProps({ ...navBarProps, isCentered: false });
                        break;
                    case MerchantPortalStyleShopComponents.ItemCard:
                        const itemCardProps: ItemCardTemplateProps = {
                            item: testItem,
                            isloading: false,
                            deviceType: previewdevicetype,
                            isPreviewMode: true,
                            merchantId,
                            BASE_PATH: '',
                            translate: translate,
                            portalOptionSettingViewModel: merchantPortalOptionSetting,
                            portalStyleSettings: merchantPortalStyleSettings,
                            messageApi,
                            config: styleState.component.styleConfig,
                        };
                        setMockComponentProps({ ...itemCardProps, isCentered: true });
                        break;
                    case MerchantPortalStyleShopComponents.Marquee:
                        console.log('loadMockComponentProps', styleState.component.styleConfig);
                        const MarqueeProps: TemplateProps = {
                            deviceType: previewdevicetype,
                            isPreviewMode: true,
                            merchantId,
                            translate,
                            portalOptionSettingViewModel: merchantPortalOptionSetting,
                            portalStyleSettings: merchantPortalStyleSettings,
                            messageApi,
                            BASE_PATH: '/base-path',
                            config: styleState.component.styleConfig,
                        };
                        setMockComponentProps({ ...MarqueeProps, isCentered: false });
                        break;
                    case MerchantPortalStyleShopComponents.ScrollToTopBtn:
                        const scrollToTopBtnProps: TemplateProps = {
                            deviceType: previewdevicetype,
                            isPreviewMode: true,
                            merchantId,
                            translate,
                            portalOptionSettingViewModel: merchantPortalOptionSetting,
                            portalStyleSettings: merchantPortalStyleSettings,
                            messageApi,
                            BASE_PATH: '/base-path',
                            config: styleState.component.styleConfig,
                        };
                        setMockComponentProps({ ...scrollToTopBtnProps, isCentered: false });
                        break;
                    default:
                        setMockComponentProps(null);
                }
            } catch (error) {
                console.error('加载组件属失:', error);
                setMockComponentProps(null);
            }
        }, [
            selectedComponentType,
            merchantId,
            merchantPortalOptionSetting,
            merchantPortalStyleSettings,
            previewdevicetype,
            testItemSpecs,
            testItem,
            translate,
            styleState.component.styleConfig,
            messageApi,
            previewContainerRef,
        ]);

        const loadMockPageProps = useCallback(async () => {
            try {
                switch (selectedPageType) {
                    case MerchantPortalStyleShopPages.LandingPage:
                        const landingPageProps: PagesTemplateProps = {
                            merchantId,
                            translate,
                            portalOptionSettingViewModel: merchantPortalOptionSetting,
                            portalStyleSettings: merchantPortalStyleSettings,
                            messageApi,
                            BASE_PATH: '/base-path',
                            config: styleState.page.styleConfig,
                            deviceType: previewdevicetype,
                            isPreviewMode: true,
                        };
                        setMockPageProps({ ...landingPageProps, isCentered: false });
                        break;
                    case MerchantPortalStyleShopPages.HomePage:
                        useGetItemsByMerchantApi(queryRequest).then((res) => {
                            if (res.result) {
                                if (testPaginationItems.data.length !== res.result.data.length + 1) {
                                    var newData = [...res.result.data, defaultTestItem(translate)];
                                    setTestPaginationItems({
                                        data: newData,
                                        totalCount: res.result.totalCount + 1,
                                        totalPage: res.result.totalPage,
                                    });
                                }
                            }
                        });
                        const homePageProps: PagesTemplateProps = {
                            merchantId,
                            translate,
                            portalOptionSettingViewModel: merchantPortalOptionSetting,
                            portalStyleSettings: merchantPortalStyleSettings,
                            messageApi,
                            BASE_PATH: '/base-path',
                            items: testPaginationItems,
                            config: styleState.page.styleConfig,
                            deviceType: previewdevicetype,
                            isPreviewMode: true,
                        };
                        setMockPageProps({ ...homePageProps, isCentered: false });
                        break;
                    case MerchantPortalStyleShopPages.ItemDetailPage:
                        const itemDetailPageProps: ItemDetailPageTemplateProps = {
                            merchantId,
                            translate,
                            portalOptionSettingViewModel: merchantPortalOptionSetting,
                            portalStyleSettings: merchantPortalStyleSettings,
                            messageApi,
                            BASE_PATH: '/base-path',
                            config: styleState.page.styleConfig,
                            deviceType: previewdevicetype,
                            item: testItem,
                            itemSpecs: testItemSpecs,
                            selectedItemSpec: selectedItemSpec,
                            purchaseQuantity: purchaseQuantity,
                            isAddToShoppingCartLoading: false,
                            isAddToTempShoppingCartLoading: false,
                            handleSelectChange: function (value: string): void {
                                setSelectedItemSpec(
                                    testItemSpecs.find((x) => x.id.toString() === value) || null
                                );
                            },
                            setPurchaseQuantity: function (value: number): void {
                                setPurchaseQuantity(value);
                            },
                            handleAddToShoppingCart: async function (): Promise<void> {
                                await new Promise(resolve => setTimeout(resolve, 1000));
                            },
                            shoppingCartItemCount: 0,
                            isPreviewMode: true,
                        };
                        setMockPageProps({ ...itemDetailPageProps, isCentered: false });
                        break;
                    case MerchantPortalStyleShopPages.SearchPage:
                        const queryOptions = await useGetQueryOptionsByMerchantIdApi(merchantId);
                        //check queryOptions is not same as testSearchQueryState.options
                        if (!ObjectComparisonHelper.areObjectsEqual(queryOptions, testSearchQueryState.options)) {
                            setTestSearchQueryState(prevState => ({
                                ...prevState,
                                options: queryOptions
                            }));
                        }
                        const searchPageProps: PagesTemplateProps = {
                            deviceType: previewdevicetype,
                            BASE_PATH: '/base-path',
                            merchantId,
                            translate,
                            portalOptionSettingViewModel: merchantPortalOptionSetting,
                            portalStyleSettings: merchantPortalStyleSettings,
                            messageApi,
                            items: testPaginationItems,
                            queryState: testSearchQueryState,
                            isPreviewMode: true,
                            config: styleState.page.styleConfig,
                        };
                        setMockPageProps({ ...searchPageProps, isCentered: false });
                        break;
                    case MerchantPortalStyleShopPages.ShoppingCartPage:
                        const shoppingCartPageProps: ShoppingCartPageTemplateProps = {
                            merchantId,
                            translate,
                            shoppingCartData: testshoppingCartData,
                            refetch: function (): void {
                            },
                            config: styleState.page.styleConfig,
                            isLogin: false,
                            tryGetSessionId: function (): BigInt {
                                return BigInt(0);
                            },
                            shoppingCartItemCount: 0,
                            handleDeleteShoppingCartItems: function (items: IShoppingCartItemViewModel[]): Promise<void> {
                                return Promise.resolve();
                            },
                            paymentMainTypes: [],
                            portalMerchantPaymentSettings: [],
                            selectedPayment: undefined,
                            setSelectedPayment: function (payment: IPortalMerchantThirdPartyPaymentSettingViewModel | undefined): void {
                                return;
                            },
                            paymentValues: undefined,
                            setPaymentValues: function (values: any): void {
                                return;
                            },
                            sendPaymentRequest: function (orderId: BigInt): Promise<void> {
                                return Promise.resolve();
                            },
                            refetchPaymentMainTypes: function (): void {
                                return;
                            },
                            refetchPaymentSettings: function (): void {
                                return;
                            },
                            logisticsMainTypes: [],
                            logisticMethods: [],
                            memberLogisticOptions: [],
                            selectedLogistic: undefined,
                            setSelectedLogistic: setSelectedLogistic,
                            selectedMemberLogisticOption: undefined,
                            setSelectedMemberLogisticOption: function (): void {
                                return;
                            },
                            memberSelectedParameters: {},
                            setMemberSelectedParameters: function (key: string, value: string): void {
                                return;
                            },
                            logisticValues: logisticValues,
                            setLogisticValues: setLogisticValues,
                            userWantReservePay: userWantReservePay,
                            setUserWantReservePay: setUserWantReservePay,
                            receiverInfos: receiverInfos,
                            setReceiverInfos: setReceiverInfos,
                            handleAddMemberLogisticOption: function (values: Record<string, string>): Promise<void> {
                                return Promise.resolve();
                            },
                            handleDeleteMemberLogisticOption: function (memberLogisticOptionId: BigInt): Promise<void> {
                                return Promise.resolve();
                            },
                            handleAddStoreAddressOption: function (logisticVM: IPortalMerchantThirdPartyLogisticSettingViewModel | undefined): Promise<void> {
                                return Promise.resolve();
                            },
                            merchantPortalOptionSetting: merchantPortalOptionSetting,
                            refetchMerchantPortalOptionSetting: function (): void {
                                return;
                            },
                            sendGAEvent: function (eventName: string, eventData: any): void {
                                return;
                            },
                            getCountrySelectionDisplayName: function (country: string): string {
                                return '';
                            },
                            deviceType: previewdevicetype,
                            BASE_PATH: '/base-path',
                            portalOptionSettingViewModel: merchantPortalOptionSetting,
                            portalStyleSettings: merchantPortalStyleSettings,
                            messageApi: messageApi,
                            selectedItems: selectedItems,
                            setSelectedItems: setSelectedItems,
                            currentStep: currentStep,
                            setCurrentStep: setCurrentStep,
                            nextStep: nextStep,
                            prevStep: prevStep,
                            handleSelectAll: handleSelectAll,
                            handleSelectItem: handleSelectItem,
                            isAddingStoreAddress: false,
                            isDeletingLogisticOption: false,
                        };
                        setMockPageProps({ ...shoppingCartPageProps, isCentered: false });
                        break;
                    case MerchantPortalStyleShopPages.ContactUsPage:
                        const contactUsPageProps: PagesTemplateProps = {
                            deviceType: previewdevicetype,
                            BASE_PATH: '/base-path',
                            merchantId,
                            translate,
                            portalOptionSettingViewModel: merchantPortalOptionSetting,
                            portalStyleSettings: merchantPortalStyleSettings,
                            messageApi,
                            items: testPaginationItems,
                            queryState: testSearchQueryState,
                            isPreviewMode: true,
                            config: styleState.page.styleConfig,
                        };
                        setMockPageProps({ ...contactUsPageProps, isCentered: false });
                        break;
                    case MerchantPortalStyleShopPages.PrivacyPolicyPage:
                        const PrivacyPolicyPageProps: PagesTemplateProps = {
                            deviceType: previewdevicetype,
                            BASE_PATH: '/base-path',
                            merchantId,
                            translate,
                            portalOptionSettingViewModel: merchantPortalOptionSetting,
                            portalStyleSettings: merchantPortalStyleSettings,
                            messageApi,
                            config: styleState.page.styleConfig,
                            isPreviewMode: true,
                        };
                        setMockPageProps({ ...PrivacyPolicyPageProps, isCentered: false });
                        break;
                    case MerchantPortalStyleShopPages.TermsOfServicePage:
                        const disclaimerPageProps: PagesTemplateProps = {
                            deviceType: previewdevicetype,
                            BASE_PATH: '/base-path',
                            merchantId,
                            translate,
                            portalOptionSettingViewModel: merchantPortalOptionSetting,
                            portalStyleSettings: merchantPortalStyleSettings,
                            messageApi,
                            config: styleState.page.styleConfig,
                            isPreviewMode: true,
                        };
                        setMockPageProps({ ...disclaimerPageProps, isCentered: false });
                        break;
                    default:
                        setMockPageProps(null);
                }
            } catch (error) {
                console.error('载页面属性失败:', error);
                setMockPageProps(null);
            }
        }, [
            selectedPageType,
            merchantId,
            merchantPortalOptionSetting,
            merchantPortalStyleSettings,
            previewdevicetype,
            testItemSpecs,
            testItem,
            translate,
            styleState.page.styleConfig,
            messageApi,
            selectedItemSpec,
            purchaseQuantity,
            testPaginationItems,
            testSearchQueryState
        ]);

        const loadMerchantItems = useCallback(async () => {
            const res = await useGetItemsByMerchantApi(queryRequest);
            if (res.result) {
                setMerchantItems([...res.result.data]);
            }
        }, [queryRequest]);

        const loadItemSpecs = useCallback(async (itemId: string) => {
            if (itemId === 'default') {
                setTestItemSpecs(defaultTestItemSpecs(translate));
            } else {
                const res = await useGetItemSpecsApi(merchantId, BigInt(itemId));
                if (res.result) {
                    setTestItemSpecs(res.result);
                }
            }
        }, [merchantId, translate]);

        const handleTestItemSelect = async (value: string) => {
            setSelectedItemId(value);
            if (value === 'default') {
                setTestItem(defaultTestItem(translate));
                setTestItemSpecs(defaultTestItemSpecs(translate));
            } else {
                const selectedItem = merchantItems.find(item => item.id.toString() === value);

                if (selectedItem) {
                    setTestItem(selectedItem);
                    await loadItemSpecs(value);
                }
            }
        };

        const showSeleceTestDataDrawer = () => {
            return (
                <div >
                    <Button onClick={() => setSeleceTestDataDrawerVisible(true)}>
                        {translate('Select Test Data')}
                    </Button>
                    <Drawer
                        placement='left'
                        title={translate("Select Test Data")}
                        onClose={() => setSeleceTestDataDrawerVisible(false)}
                        open={seleceTestDataDrawerVisible}
                        width={400}
                    >
                        <Form form={form} layout="vertical">
                            <Form.Item name="testItem" label={translate("Item")}>
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder={translate("Select")}
                                    onChange={handleTestItemSelect}
                                    value={selectedItemId}
                                    defaultValue={'default'}
                                >
                                    <Select.Option value="default">
                                        {translate(defaultTestItem(translate).name)} (Default)
                                    </Select.Option>
                                    {merchantItems.map(item => (
                                        <Select.Option key={item.id.toString()} value={item.id.toString()}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Form>
                        <Title level={5}>{translate("Specs")}</Title>
                        <List
                            dataSource={testItemSpecs}
                            renderItem={spec => (
                                <List.Item>
                                    <List.Item.Meta
                                        title={spec.name}
                                        description={
                                            <>
                                                <Text>{`${translate("Price")}: ${spec.sellPrice} ${spec.currency}`}</Text>
                                                <br />
                                                <Text>{`${translate("Stock")}: ${spec.isManageStockAmount ? spec.stockAmount : translate("Not Managed")}`}</Text>
                                            </>
                                        }
                                    />
                                </List.Item>
                            )}
                        />
                    </Drawer>
                </div>
            );
        };

        useEffect(() => {
            loadComponentTemplate();
        }, [
            activeTab,
            selectedComponentType,
            styleState.component.style,
            loadComponentTemplate,
        ]);

        useEffect(() => {
            loadMockComponentProps();
        }, [
            activeTab,
            selectedComponentType,
            styleState.component.style,
            previewdevicetype,
            styleState.component.styleConfig,
            refreshComponentCount,
            testItemSpecs,
            testItem,
            loadMockComponentProps,
        ]);

        useEffect(() => {
            loadPageTemplate();
        }, [activeTab,
            selectedPageType,
            styleState.page.style,
            loadPageTemplate]);

        useEffect(() => {
            loadMockPageProps();
        }, [
            activeTab,
            selectedPageType,
            styleState.page.style,
            previewdevicetype,
            styleState.page.styleConfig,
            refreshPageCount,
            testItemSpecs,
            testItem,
            loadMockPageProps,
            selectedItemSpec,
            purchaseQuantity,
        ]);

        useImperativeHandle(ref, () => ({
            onRefresh: () => {
                loadComponentTemplate();
                loadPageTemplate();
                loadMockComponentProps();
                loadMockPageProps();
                loadMerchantItems();
            },
        }));

        useEffect(() => {
            const updateWidth = () => {
                if (previewContainerRef.current) {
                    const rect = previewContainerRef.current.getBoundingClientRect();
                    setContainerWidth(rect.width);
                }
            };

            updateWidth(); // 初始化寬度
            window.addEventListener('resize', updateWidth);

            // 創建一個 ResizeObserver 來監聽容器大小變化
            const resizeObserver = new ResizeObserver(updateWidth);
            if (previewContainerRef.current) {
                resizeObserver.observe(previewContainerRef.current);
            }

            loadMerchantItems();

            return () => {
                window.removeEventListener('resize', updateWidth);
                resizeObserver.disconnect();
            };
        }, []);

        const handleStyleChange = (type: 'component' | 'page', value: number) => {
            setStyleState((prevState) => ({
                ...prevState,
                [type]: {
                    ...prevState[type],
                    style: value,
                },
            }));
        };

        const handleConfigChange = (
            type: 'component' | 'page',
            key: string,
            value: string
        ) => {
            setStyleState((prevState) => ({
                ...prevState,
                [type]: {
                    ...prevState[type],
                    styleConfig: {
                        ...prevState[type].styleConfig,
                        [key]: value,
                    },
                },
            }));
            if (type === 'component') {
                console.log(styleState.component.styleConfig);
                setRefreshComponentCount((prev) => prev + 1);
            } else {
                console.log(styleState.page.styleConfig);
                setRefreshPageCount((prev) => prev + 1);
            }
        };

        // 预览设备类型切换
        const handlePreviewDeviceChange = (type: DeviceType) => {
            setPreviewDeviceType(type);
        };

        const getPreviewWidth = () => {
            switch (previewdevicetype) {
                case DeviceType.Mobile:
                    return '375px';
                case DeviceType.LapTop:
                    return '768px';
                case DeviceType.Desktop:
                default:
                    return '1024px';
            }
        };

        useEffect(() => {
            setSelectedItemId('default');
            setTestItem(defaultTestItem(translate));
            setTestItemSpecs(defaultTestItemSpecs(translate));
        }, [translate]);

        // 監聽 merchantPortalStyleSettings 變化
        useEffect(() => {
            // 重新加載組件模板
            loadComponentTemplate();
            // 重新加載頁面模板 
            loadPageTemplate();
            // 重新加載組件屬性
            loadMockComponentProps();
            // 重新加載頁面屬性
            loadMockPageProps();
        }, [merchantPortalStyleSettings]);

        const items = [
            {
                key: MerchantPortalStyleSettingType.Components,
                label: translate('Components'),
                children: (
                    <div>
                        <Row style={{ marginBottom: 16 }}>
                            <Col span={windowdevicetype === DeviceType.Desktop ? 12 : 24} style={{ marginBottom: windowdevicetype === DeviceType.Desktop ? 0 : 16 }}>
                                <Flex justify="center" align="center">
                                    <Select
                                        style={{ width: 200 }}
                                        value={selectedComponentType}
                                        onChange={(value) => setSelectedComponentType(value)}
                                    >
                                        {Object.values(MerchantPortalStyleShopComponents)
                                            .filter(component => component !== MerchantPortalStyleShopComponents.ScrollToTopBtn)
                                            .map((component) => (
                                                <Select.Option key={component} value={component}>
                                                    {translate(component)}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Flex>
                            </Col>
                            <Col span={windowdevicetype === DeviceType.Desktop ? 12 : 24}>
                                <Flex justify="center" align="center">
                                    <Button
                                        icon={<MobileOutlined />}
                                        onClick={() => handlePreviewDeviceChange(DeviceType.Mobile)}
                                        type={previewdevicetype === DeviceType.Mobile ? 'primary' : 'default'}
                                    >
                                        {translate('Mobile')}
                                    </Button>
                                    <Button
                                        icon={<TabletOutlined />}
                                        onClick={() => handlePreviewDeviceChange(DeviceType.LapTop)}
                                        type={previewdevicetype === DeviceType.LapTop ? 'primary' : 'default'}
                                    >
                                        {translate('Laptop')}
                                    </Button>
                                    <Button
                                        icon={<LaptopOutlined />}
                                        onClick={() => handlePreviewDeviceChange(DeviceType.Desktop)}
                                        type={previewdevicetype === DeviceType.Desktop ? 'primary' : 'default'}
                                    >
                                        {translate('Desktop')}
                                    </Button>
                                </Flex>
                            </Col>
                        </Row>
                        {activeTab === 'Components' && (
                            <PreviewContainer
                                ref={previewContainerRef}
                                containerwidth={containerwidth}
                                previewwidth={getPreviewWidth()}
                                previewdevicetype={previewdevicetype}
                                windowdevicetype={windowdevicetype}
                            >
                                <div>
                                    {ComponentTemplateComponent ? (
                                        mockComponentProps ? (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: mockComponentProps.isCentered
                                                        ? 'center'
                                                        : 'flex-start',
                                                    alignItems: mockComponentProps.isCentered
                                                        ? 'center'
                                                        : 'flex-start',
                                                    height: '100%',
                                                    width: '100%',
                                                }}
                                            >
                                                <ComponentTemplateComponent {...mockComponentProps} />
                                            </div>
                                        ) : (
                                            <Empty description={translate('No props')} />
                                        )
                                    ) : (
                                        <Empty description={translate('No component template')} />
                                    )}
                                </div>
                            </PreviewContainer>
                        )}
                        <Flex justify="center" align="center" style={{ padding: '10px' }} gap="20px">
                            {showSeleceTestDataDrawer()}
                            <StyleConfigSelector
                                type={MerchantPortalStyleSettingType.Components}
                                name={selectedComponentType}
                                selectedStyle={styleState.component.style}
                                styleConfig={styleState.component.styleConfig}
                                configFields={styleState.component.configFields}
                                onStyleChange={(value) => handleStyleChange('component', value)}
                                onConfigChange={(key, value) =>
                                    handleConfigChange('component', key, value)
                                }
                                styleOptions={[
                                    { value: 0, label: translate('Style 1') },
                                    { value: 1, label: translate('Style 2') },
                                ]}
                                abortSignal={abortController.current?.signal}
                            />
                        </Flex>
                    </div>
                ),
            },
            {
                key: MerchantPortalStyleSettingType.Pages,
                label: translate('Pages'),
                children: (
                    <div>
                        <Row style={{ marginBottom: 16 }}>
                            <Col span={windowdevicetype === DeviceType.Desktop ? 12 : 24} style={{ marginBottom: windowdevicetype === DeviceType.Desktop ? 0 : 16 }}>
                                <Flex justify="center" align="center">
                                    <Select
                                        style={{ width: 200 }}
                                        value={selectedPageType}
                                        onChange={(value: MerchantPortalStyleShopPages) =>
                                            setSelectedPageType(value)
                                        }
                                    >
                                        {Object.values(MerchantPortalStyleShopPages).map((page) => (
                                            <Select.Option key={page} value={page}>
                                                {translate(page)}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Flex>
                            </Col>
                            <Col span={windowdevicetype === DeviceType.Desktop ? 12 : 24}>
                                <Flex justify="center" align="center">
                                    <Button
                                        icon={<MobileOutlined />}
                                        onClick={() => handlePreviewDeviceChange(DeviceType.Mobile)}
                                        type={previewdevicetype === DeviceType.Mobile ? 'primary' : 'default'}
                                    >
                                        {translate('Mobile')}
                                    </Button>
                                    <Button
                                        icon={<TabletOutlined />}
                                        onClick={() => handlePreviewDeviceChange(DeviceType.LapTop)}
                                        type={previewdevicetype === DeviceType.LapTop ? 'primary' : 'default'}
                                    >
                                        {translate('Laptop')}
                                    </Button>
                                    <Button
                                        icon={<LaptopOutlined />}
                                        onClick={() => handlePreviewDeviceChange(DeviceType.Desktop)}
                                        type={previewdevicetype === DeviceType.Desktop ? 'primary' : 'default'}
                                    >
                                        {translate('Desktop')}
                                    </Button>
                                </Flex>
                            </Col>
                        </Row>
                        {activeTab === 'Pages' && (
                            <PreviewContainer
                                ref={previewContainerRef}
                                containerwidth={containerwidth}
                                previewwidth={getPreviewWidth()}
                                previewdevicetype={previewdevicetype}
                                windowdevicetype={windowdevicetype}
                            >
                                <div>
                                    {PageTemplateComponent ? (
                                        mockPageProps ? (
                                            <PageTemplateComponent {...mockPageProps} />
                                        ) : (
                                            <Empty description={translate('No props')} />
                                        )
                                    ) : (
                                        <Empty description={translate('No page template')} />
                                    )}
                                </div>
                            </PreviewContainer>
                        )}

                        <Flex justify="center" align="center" style={{ padding: '10px' }} gap="20px">
                            {showSeleceTestDataDrawer()}
                            <StyleConfigSelector
                                type={MerchantPortalStyleSettingType.Pages}
                                name={selectedPageType}
                                selectedStyle={styleState.page.style}
                                styleConfig={styleState.page.styleConfig}
                                configFields={styleState.page.configFields}
                                onStyleChange={(value) => handleStyleChange('page', value)}
                                onConfigChange={(key, value) => handleConfigChange('page', key, value)}
                                styleOptions={[
                                    { value: 0, label: translate('Style 1') },
                                    { value: 1, label: translate('Style 2') },
                                ]}
                                abortSignal={abortController.current?.signal}
                            />
                        </Flex>
                    </div>
                ),
            },
        ];

        if (isMerchantPortalStyleSettingsLoading) {
            return <LoadingComponent />;
        }

        return (
            <Row>
                <Col span={24}>
                    <Tabs
                        items={items}
                        activeKey={activeTab}
                        onChange={(key) => setActiveTab(key as MerchantPortalStyleSettingType)}
                    />
                </Col>
            </Row>
        );
    }
);

export default ShopPreview;
