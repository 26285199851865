import { ISwitchBackOfficeMerhcnatRequest } from '@/interfaces/Requests/Requests';
import { DeviceType } from '@/Templates/enums/templateEnums';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { IGlobalContextType, IGlobalProviderProps } from '../../interfaces/GlobalContextType';
import { useGetAccessibleMerchantsApi, useIsBackOfficeMemberApi } from '../api/merchants';
import { useGetMyRolesApi } from '../api/permission';
import { useGetMerchantPortalOptionSettingApi, useGetMerchantPortalStyleSettingsApi, useSwitchBackOfficeMerhcnatApi } from '../api/portals';
import { useGetSSLBindingApi } from '../api/webSiteSettings';
import { GenereateHelper } from '../helpers/GenerateHelper';

export const GlobalContext = React.createContext<IGlobalContextType>(null!);

export const GlobalProvider: React.FC<IGlobalProviderProps> = ({ messageApi, children }): React.ReactNode => {
  const navigate = useNavigate();
  const generateHelper = useMemo(() => new GenereateHelper(), []);
  const { t, i18n } = useTranslation('Global');
  const [hasMerchant, setHasMerchant] = useState<boolean>(false);
  const storedMerchantId = localStorage.getItem('MerchantId');
  const initialMerchantId = storedMerchantId ? BigInt(storedMerchantId) : undefined;
  const [merchantId, setMerchantId] = useState<BigInt | undefined>(initialMerchantId);
  const [isBackOffice, setIsBackOffice] = useState<boolean>(false);
  const [merchantPermissionRoles, setMerchantPermissionRoles] = useState<string[] | undefined>(undefined);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [systemConfig, setSystemConfig] = useState<IGlobalContextType['systemConfig']>({ Roles: [] });
  const [isHideNavBar, setIsHideNavBar] = useState<IGlobalContextType['isHideNavBar']>(false);
  const [isHideAnnouncement, setIsHideAnnouncement] = useState<IGlobalContextType['isHideAnnouncement']>(true);
  const [navBarHeight, setNavBarHeight] = useState<IGlobalContextType['navBarHeight']>(0);
  const [isPageLoading, setIsPageLoading] = useState<IGlobalContextType['isPageLoading']>(false);
  const [merchantPortalOptionSetting, setMerchantPortalOptionSetting] = useState<IGlobalContextType['merchantPortalOptionSetting']>(undefined);
  const [isLogin, setIsLogin] = useState<IGlobalContextType['isLogin']>(() => {
    const token = localStorage.getItem("JWTToken");
    return token !== null;
  });
  const [deviceType, setDeviceType] = useState<IGlobalContextType['deviceType']>(DeviceType.Desktop);
  const [portalLink, setPortalLink] = useState<string>('');
  const [merchantPortalStyleSettings, setMerchantPortalStyleSettings] = useState<IGlobalContextType['merchantPortalStyleSettings']>([]);
  const [accessibleMerchants, setAccessibleMerchants] = useState<IGlobalContextType['accessibleMerchants']>(undefined);
  const [isPortalLinkLoading, setIsPortalLinkLoading] = useState<boolean>(false);
  const { data: isBackOfficeData, refetch: refetchIsBackOffice } = useQuery(
    'isBackOffice',
    () => useIsBackOfficeMemberApi(),
    {
      enabled: false,
      onSuccess: (response) => {
        if (response.isSuccess) {
          setIsBackOffice(response.result || false);
        }
        else {
          setIsBackOffice(false);
        }
      },
      onSettled: () => {
        setIsInitialized(true);
      }
    }
  );

  const getMerchantPortalOptionSetting = useMutation(
    (params: { merchantId: BigInt, signal?: AbortSignal }) =>
      useGetMerchantPortalOptionSettingApi(params.merchantId, params.signal),
    {
      onSuccess: (response) => {
        if (response.isSuccess) {
          setMerchantPortalOptionSetting(response.result);
        } else {
          messageApi.error(t(response.message || 'Operation failed'));
        }
      }
    }
  );

  const getMerchantPortalStyleSettings = useMutation(
    (params: { merchantId: BigInt, signal?: AbortSignal }) =>
      useGetMerchantPortalStyleSettingsApi(params.merchantId, params.signal),
    {
      onSuccess: (response) => {
        if (response.isSuccess) {
          setMerchantPortalStyleSettings(response.result || []);
        } else {
          messageApi.error(t(response.message || 'Operation failed'));
        }
      }
    }
  );

  const updateSystemConfig = useCallback((config: { Roles: string[] }) => {
    setSystemConfig(config);
  }, []);

  const updateNavBarHeight = useCallback((height: number) => {
    setNavBarHeight(height);
  }, []);

  const updateIsLogin = useCallback((isLogin: boolean) => {
    setIsLogin(isLogin);
  }, []);

  const updateDeviceType = useCallback((deviceType: DeviceType) => {
    setDeviceType(deviceType);
  }, []);

  const getMerchantIdFromLocalStorage = (): string | null => {
    return localStorage.getItem('MerchantId');
  };

  const getPortalLink = useMutation(
    async (merchantId: string) => {
      const response = await useGetSSLBindingApi();
      if (response && response.result && response.result.filter(r => r.isValidated).length > 0) {
        var firstValidated = response.result.find(r => r.isValidated);
        return `https://${firstValidated?.merchantDomain || ''}`;
      } else {
        const portalHostName = window.location.hostname.replace('backoffice', 'portal');
        return `https://${portalHostName}${process.env.ASPNETCORE_ENVIRONMENT === 'Production' ? '' : ':5173'}/${merchantId}/`;
      }
    },
    {
      onSuccess: (link) => {
        setPortalLink(link);
      },
      onError: () => {
        messageApi.error('Operation failed');
      }
    }
  );

  const tryGetPortalLink = useCallback(async (merchantId: string): Promise<void> => {
    getPortalLink.mutate(merchantId);
  }, []);

  const getMerchantAccessible = useMutation(
    async () => await useGetAccessibleMerchantsApi(undefined),
    {
      onSuccess: (response) => {
        if (!response.isSuccess) {
          setAccessibleMerchants(undefined);
          messageApi.error(t(response.message || 'Operation failed'));
        }
        setAccessibleMerchants(response.result);
      }
    }
  );

  const switchBackOfficeMerchant = useMutation(
    async (request: ISwitchBackOfficeMerhcnatRequest) => {
      return await useSwitchBackOfficeMerhcnatApi(request);
    },
    {
      onSuccess: (response, request) => {
        if (response.isSuccess) {
          localStorage.setItem("JWTToken", response.result!);
        } else {
          messageApi.error(t(response.message || 'Operation failed'));
        }
      },
    }
  );

  const getMerchantPermissionRoles = useMutation(
    () => useGetMyRolesApi(undefined),
    {
      onSuccess: (response) => {
        if (response.isSuccess) {
          setMerchantPermissionRoles(response.result);
        } else {
          setMerchantPermissionRoles([]);
        }
      }
    }
  );

  const setMerchantIdAggregate = useCallback(async (newMerchantId: BigInt) => {
    if (merchantId?.toString() !== newMerchantId.toString()) {
      localStorage.setItem('MerchantId', newMerchantId.toString());
      setMerchantId(newMerchantId);

      const response = await switchBackOfficeMerchant.mutateAsync({
        merchantId: newMerchantId
      });

      if (!response.isSuccess) {
        messageApi.error(t(response.message || 'Operation failed'));
        return;
      }
    }

    setHasMerchant(true);

    await Promise.all([
      getMerchantPortalOptionSetting.mutateAsync({ merchantId: newMerchantId }),
      getMerchantPortalStyleSettings.mutateAsync({ merchantId: newMerchantId }),
      getPortalLink.mutateAsync(newMerchantId.toString()),
      refetchIsBackOffice(),
      getMerchantPermissionRoles.mutateAsync(),
      getMerchantAccessible.mutateAsync()
    ]);
  }, [
    merchantId,
    switchBackOfficeMerchant,
    messageApi,
    t,
    getMerchantPortalOptionSetting,
    getMerchantPortalStyleSettings,
    getPortalLink,
    refetchIsBackOffice,
    getMerchantPermissionRoles,
    getMerchantAccessible
  ]);

  const removeMerchantIdAggregate = useCallback(() => {
    localStorage.removeItem('MerchantId');
    setMerchantId(undefined);
    setHasMerchant(false);
    setAccessibleMerchants(undefined);
    setMerchantPermissionRoles(undefined);
  }, []);

  useEffect(() => {
    const storedMerchantId = localStorage.getItem('MerchantId');
    if (storedMerchantId) {
      setMerchantId(BigInt(storedMerchantId));
      setMerchantIdAggregate(BigInt(storedMerchantId));
    }
  }, []);

  return (
    <GlobalContext.Provider value={{
      generateHelper,
      messageApi,
      hasMerchant,
      setHasMerchant,
      merchantId,
      getMerchantIdFromLocalStorage,
      setMerchantIdAggregate,
      isSetMerchantIdAggregateLoading: switchBackOfficeMerchant.isLoading ||
        getMerchantPortalOptionSetting.isLoading ||
        getMerchantPortalStyleSettings.isLoading ||
        getPortalLink.isLoading ||
        getMerchantPermissionRoles.isLoading ||
        getMerchantAccessible.isLoading,
      removeMerchantIdAggregate,
      isBackOffice,
      setIsBackOffice,
      merchantPermissionRoles,
      isMerchantPermissionRolesLoading: getMerchantPermissionRoles.isLoading,
      setMerchantPermissionRoles,
      isInitialized,
      isLogin,
      setIsLogin: updateIsLogin,
      systemConfig,
      setSystemConfig: updateSystemConfig,
      isHideNavBar,
      setIsHideNavBar,
      navBarHeight,
      setNavBarHeight: updateNavBarHeight,
      isHideAnnouncement,
      setIsHideAnnouncement,
      isPageLoading,
      setIsPageLoading,
      deviceType,
      setDeviceType: updateDeviceType,
      merchantPortalOptionSetting,
      getMerchantPortalOptionSetting,
      portalLink,
      tryGetPortalLink,
      tryGetPortalLinkLoading: getPortalLink.isLoading,
      merchantPortalStyleSettings,
      isMerchantPortalStyleSettingsLoading: getMerchantPortalStyleSettings.isLoading,
      getMerchantPortalStyleSettings,
      accessibleMerchants,
      getAccessibleMerchants: getMerchantAccessible,
      getAccessibleMerchantsLoading: getMerchantAccessible.isLoading,
      switchBackOfficeMerchant,
    }}>
      {children}
    </GlobalContext.Provider>
  );
};
