import DomigoLogo from '@/assets/Domigo-Logo-removebg-preview.png';
import { useThirdPartyLoginApi } from "@/lib/api/thirdPartyLogin";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { DeviceType } from '@/Templates/enums/templateEnums';
import { IOperationResultT } from "@/Templates/interfaces/templatesInterfaces";
import { LockOutlined, PhoneOutlined, UserOutlined } from "@ant-design/icons";
import { useGoogleLogin } from "@react-oauth/google";
import { Button, Divider, Flex, Form, Image, Input, InputRef, Tabs } from "antd";
import FormItem from "antd/es/form/FormItem";
import { MD5 } from "crypto-js";
import { useContext, useEffect, useRef, useState } from "react";
import { useMutation } from 'react-query';
import { useNavigate } from "react-router-dom";
import { LoginTypeEnum, ThirdPartyLoginProviders } from '../../enums/Enums';
import { IBackOfficeThirdPartyLoginRequest, ILoginRequest } from '../../interfaces/Requests/Requests';
import { useMerchantLoginApi } from "../../lib/api/merchants";
import { GlobalContext } from '../../lib/contexts/GlobalContext';
import ThirdPartyLogin from './components/ThirdPartyLogin';

const LoginPage = () => {
  const { translate } = useContext(TranslationContext);
  const { messageApi,
    setIsPageLoading,
    setIsLogin,
    setIsHideNavBar,
    removeMerchantIdAggregate,
    setIsHideAnnouncement,
    deviceType
  } = useContext(GlobalContext);
  const emailRef = useRef<InputRef>(null);
  const [loginRequest, setLoginRequest] = useState<ILoginRequest>({
    loginType: LoginTypeEnum.Email,
    email: process.env.TEST_ACCOUNT ? process.env.TEST_ACCOUNT : "",
    hashPassword: process.env.TEST_PASSWORD ? process.env.TEST_PASSWORD : "",
    phoneNumber: process.env.TEST_PHONE_NUMBER ? process.env.TEST_PHONE_NUMBER : "",
  });
  const googleLoginBtn = useGoogleLogin({
    flow: 'auth-code',
    redirect_uri: process.env.GoogleRedirectUri,
    ux_mode: 'redirect',
    onError: errorResponse => console.log(errorResponse),
  });
  const handleFacebookLogin = (token: string, method: string, state?: string) => {
    var request: IBackOfficeThirdPartyLoginRequest = {
      provider: ThirdPartyLoginProviders.Facebook,
      accessToken: token,
      extraData: `${window.location.origin}/facebook-callback`
    };
    thirdPartyLoginMutate(request);
  }
  const handleLineLogin = (token: string, method: string, state?: string) => {
    var request: IBackOfficeThirdPartyLoginRequest = {
      provider: ThirdPartyLoginProviders.Line,
      accessToken: token,
      extraData: `${window.location.origin}/line-callback`
    };
    thirdPartyLoginMutate(request);
  }
  const handlleGoogleLogin = (token: string, method: string, state?: string) => {
    var request: IBackOfficeThirdPartyLoginRequest = {
      provider: ThirdPartyLoginProviders.Google,
      accessToken: token,
      extraData: `${window.location.origin}/google-callback`
    };
    thirdPartyLoginMutate(request);
  }
  const handleThirdPartyLogin = (token: string, method: string, state?: string) => {
    switch (method) {
      case "google":
        handlleGoogleLogin(token, method, state);
        break;
      case "line":
        handleLineLogin(token, method, state);
        break;
      case "facebook":
        handleFacebookLogin(token, method, state);
        break;
      default:
        messageApi.error(translate('Not supported'));
        break;
    }
  }
  const loginResponseHandler = (data: IOperationResultT<string>) => {
    if (data?.isSuccess === true) {
      localStorage.setItem("JWTToken", data.result!); //SET JWTToken
      removeMerchantIdAggregate();
      setIsLogin(true);
      messageApi.success(translate('Operation success'));
      setIsHideNavBar(false);
      navigate(`/`, { replace: true });
    }
    else {
      messageApi.error(data?.message);
    }
  }
  const { data, mutate: memberLoginMutate, isLoading: loginMutating } = useMutation(async (request: ILoginRequest) => {
    setIsPageLoading(true);
    return await useMerchantLoginApi(request);
  },
    {
      onSuccess: (data) => {
        loginResponseHandler(data);
      },
      onSettled: () => {
        setIsPageLoading(false);
      }
    }
  );
  const { mutate: thirdPartyLoginMutate, isLoading: thirdPartyLoginMutating } = useMutation(async (reqeust: IBackOfficeThirdPartyLoginRequest) => {
    setIsPageLoading(true);
    return await useThirdPartyLoginApi(reqeust);
  },
    {
      onSuccess: (data) => {
        loginResponseHandler(data);
      },
      onSettled: () => {
        setIsPageLoading(false);
      }
    }
  );
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const handelChange = (type: any, value: any) => {
    setLoginRequest({ ...loginRequest, [type]: value });
  };
  function handelSubmit(value: any) {
    const hashedRequest = {
      ...loginRequest,
      hashPassword: MD5(loginRequest.hashPassword).toString().toLowerCase()
    };
    memberLoginMutate(hashedRequest);
  };

  const [loginType, setLoginType] = useState<LoginTypeEnum>(LoginTypeEnum.Email);

  const handleLoginTypeChange = (type: LoginTypeEnum) => {
    setLoginType(type);
    setLoginRequest({
      ...loginRequest,
      loginType: type,
      email: '',
      phoneNumber: '',
      hashPassword: ''
    });
    form.resetFields();
  };

  useEffect(() => {
    setIsHideNavBar(true);
    setIsHideAnnouncement(true);
    return () => {
      setIsHideNavBar(false);
      setIsHideAnnouncement(false);
    }
  }, []);

  return (
    <div className={`h-[100vh] ${deviceType !== DeviceType.Mobile ? 'flex justify-center items-center' : ''}`}>
      <div className={`bg-white p-8 rounded-lg  w-full max-w-md ${deviceType !== DeviceType.Mobile ? 'shadow-md' : ''}`}>
        <Flex justify="center" align="middle" className="mb-6">
          <Image
            src={DomigoLogo}
            width={100}
            height={100}
            preview={false}
          />
        </Flex>
        <Tabs
          activeKey={loginType}
          onChange={(key) => handleLoginTypeChange(key as LoginTypeEnum)}
          centered
          items={[
            {
              key: LoginTypeEnum.Email,
              label: translate('Email Login'),
              children: (
                <Form
                  form={form}
                  name="login-form"
                  initialValues={{
                    email: loginRequest.email,
                    hashPassword: loginRequest.hashPassword,
                  }}
                >
                  <FormItem
                    name="email"
                    rules={[
                      { required: true, message: translate('is required') },
                      {
                        pattern: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
                        message: translate("Must be a valid email format"),
                      },
                    ]}
                  >
                    <Input
                      prefix={<UserOutlined className="site-form-item-icon" />}
                      placeholder={translate('Email')}
                      ref={emailRef}
                      value={loginRequest.email}
                      onChange={(e) => handelChange("email", e.target.value)}
                    />
                  </FormItem>

                  <FormItem
                    name="hashPassword"
                    rules={[{ required: true, message: translate('is required') }]}
                  >
                    <Input.Password
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      placeholder={translate('Password')}
                      value={loginRequest.hashPassword}
                      onChange={(e) => handelChange("hashPassword", e.target.value)}
                    />
                  </FormItem>

                  <FormItem>
                    <Flex justify="center">
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button mr-2"
                        loading={thirdPartyLoginMutating || loginMutating}
                        onClick={(value) => handelSubmit(value)}
                      >
                        {translate("Login")}
                      </Button>
                    </Flex>
                  </FormItem>
                </Form>
              )
            },
            {
              key: LoginTypeEnum.PhoneNumber,
              label: translate('Phone Login'),
              children: (
                <Form
                  form={form}
                  name="phone-login-form"
                  initialValues={{
                    phoneNumber: loginRequest.phoneNumber,
                    hashPassword: loginRequest.hashPassword,
                  }}
                >
                  <FormItem
                    name="phoneNumber"
                    rules={[
                      { required: true, message: translate('is required') },
                      {
                        pattern: /^09\d{8}$/,
                        message: translate("Must be a valid phone number format"),
                      },
                    ]}
                  >
                    <Input
                      prefix={<PhoneOutlined className="site-form-item-icon" />}
                      placeholder={translate('Phone Number')}
                      value={loginRequest.phoneNumber}
                      onChange={(e) => handelChange("phoneNumber", e.target.value)}
                    />
                  </FormItem>

                  <FormItem
                    name="hashPassword"
                    rules={[{ required: true, message: translate('is required') }]}
                  >
                    <Input.Password
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      placeholder={translate('Password')}
                      value={loginRequest.hashPassword}
                      onChange={(e) => handelChange("hashPassword", e.target.value)}
                    />
                  </FormItem>

                  <FormItem>
                    <Flex justify="center">
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button mr-2"
                        loading={thirdPartyLoginMutating || loginMutating}
                        onClick={(value) => handelSubmit(value)}
                      >
                        {translate("Login")}
                      </Button>
                    </Flex>
                  </FormItem>
                </Form>
              )
            }
          ]}
        />

        <Divider>{translate('Third Party') + ' ' + translate('Login')}</Divider>
        <ThirdPartyLogin
          isLoading={thirdPartyLoginMutating || loginMutating}
          googleLoginBtn={googleLoginBtn}
          handleThirdPartyTokenLogin={handleThirdPartyLogin}
          translate={translate}
          deviceType={deviceType}
        />

        <Divider />
        <Flex wrap="wrap" gap={16} justify="center" align="center">
          {translate('New to our platform') + '?'}
          <Button
            type="link"
            onClick={() => navigate(`/register`)}
            className="ml-2"
          >
            {translate('Register now')}
          </Button>
        </Flex>
      </div>
    </div >
  );
};

export default LoginPage;
