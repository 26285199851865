import { ImageUseageType } from "@/enums/Enums";
import { ICreateItemRequest } from "@/interfaces/Requests/Requests";
import { useCreateItemApi } from "@/lib/api/items";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { ItemContext } from "@/lib/contexts/ItemContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { Button, Divider, Flex, Modal, Steps, Tooltip } from "antd";
import { load } from "cheerio";
import { useContext, useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import 'react-quill/dist/quill.snow.css';
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import AlterDescription from "./Components/AlterDescription";
import BasicInformation, { IBasicInformationRef } from "./Components/BasicInformation";
import PreviewItem from "./Components/PreviewItem";
import SpecsForm from "./Components/SpecsForm";

export enum AddItemStep {
    FillInfo,
    Preview
}

const AddItemPage = () => {
    const { merchantId, messageApi } = useContext(GlobalContext);
    const { translate, i18nLanguage } = useContext(TranslationContext);
    const { itemViewModel, itemSpecs, itemConverImageFiles } = useContext(ItemContext);
    const navigate = useNavigate();
    const basicInfoRef = useRef<IBasicInformationRef>();
    const [currentStep, setCurrentStep] = useState<AddItemStep>(AddItemStep.FillInfo);
    const [createItemRequest, setCreateItemRequest] = useState<ICreateItemRequest>({
        images: [],
        item: {
            name: '',
            title: '',
            briefly: '',
            description: '',
        },
        itemSpecs: [
        ],
        itemTagNames: [],
    });
    const [isBasicInfoValid, setIsBasicInfoValid] = useState(false);
    const [isSpecsAllValid, setIsSpecsAllValid] = useState(false);
    const { mutateAsync } = useMutation(async (request: FormData) => await useCreateItemApi(request), {
        onSuccess: (response) => {
            if (response.isSuccess) {
                messageApi.success(translate('Operation success'));
                navigate(`/itemManage`);
            }
            else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    });

    useEffect(() => {
        if (itemViewModel) {
            setCreateItemRequest({
                ...createItemRequest,
                item: {
                    name: itemViewModel.name,
                    title: itemViewModel.title,
                    briefly: itemViewModel.briefly,
                    description: itemViewModel.description,
                },
                itemTagNames: itemViewModel.itemTags.map((tag) => tag.name),
            });
        }
    }, [itemViewModel]);

    useEffect(() => {
        if (currentStep === AddItemStep.FillInfo) {
            basicInfoRef.current?.validate();
        }
    }, [currentStep]);

    function backToItemManage() {
        Modal.confirm({
            title: translate('Back to Manage'),
            content: translate('Do you want to cancel the item creation?'),
            okText: translate('Yes'),
            onOk() {
                navigate(`/itemManage`);
            },
            cancelText: translate('No'),
        });
    }

    async function createItemAsync() {
        // 檢查規格名稱和售價
        const invalidSpecs = itemSpecs.filter(spec =>
            !spec.name.trim() || spec.sellPrice === 0
        );

        if (invalidSpecs.length > 0) {
            const hasEmptyName = invalidSpecs.some(spec => !spec.name.trim());
            const hasZeroPrice = invalidSpecs.some(spec => spec.sellPrice === 0);

            let errorMessage = '';
            if (hasEmptyName && hasZeroPrice) {
                errorMessage = translate('Spec name cannot be empty and sell price cannot be 0');
            } else if (hasEmptyName) {
                errorMessage = translate('Spec name cannot be empty');
            } else {
                errorMessage = translate('Sell price cannot be 0');
            }

            Modal.confirm({
                title: translate('Warning'),
                content: `${errorMessage}. ${translate('Are you sure you want to proceed')}?`,
                okText: translate('Yes'),
                cancelText: translate('No'),
                onOk: () => showCreateItemConfirmation(),
            });
        } else {
            showCreateItemConfirmation();
        }
    }

    function showCreateItemConfirmation() {
        Modal.confirm({
            title: translate('Confirm'),
            content: translate('Do you want to create the item?'),
            okText: translate('Yes'),
            async onOk() {
                var getParsedDeiscriptionContent = await getParsedDeiscriptionAsync(createItemRequest, itemViewModel?.description);
                var finalRequest = CombineitemConverImageFiles(getParsedDeiscriptionContent);
                var formData = new FormData();
                formData.append('MerchantId', merchantId!.toString());
                formData.append('Item.Name', finalRequest.item.name);
                formData.append('Item.Title', finalRequest.item.title);
                formData.append('Item.Briefly', finalRequest.item.briefly);
                formData.append('Item.Description', finalRequest.item.description);
                finalRequest.itemSpecs = itemSpecs;
                finalRequest.itemSpecs.forEach((itemSpec: any, index) => {
                    Object.keys(itemSpec).forEach((key) => {
                        formData.append(`ItemSpecs[${index}].${key}`, itemSpec[key] as string);
                    });
                });
                finalRequest.itemTagNames.forEach((tag) => {
                    formData.append('ItemTagNames', tag);
                });
                finalRequest.images.forEach((file) => {
                    formData.append('Images', file);
                });
                await mutateAsync(formData);
            },
            cancelText: translate('No'),
        });
    }

    async function getParsedDeiscriptionAsync(request: ICreateItemRequest, description: string): Promise<ICreateItemRequest> {
        let content = description;
        if (!content) {
            return { ...request, images: [...request.images], item: { ...request.item, description: '' } };
        }

        const $ = load(content);
        const promises = $('img').map(async (index, element) => {
            const src = element.attribs.src;
            if (src.startsWith('data:')) {
                const res = await fetch(src);
                const blob = await res.blob();
                const fileName = `${ImageUseageType.ItemDescription}_${uuidv4()}.jpg`;
                const file = new File([blob], fileName, { type: 'image/jpeg' });
                content = content!.replace(src, fileName);
                request.images.push(file);
            }
        }).get();

        await Promise.all(promises);
        return { ...request, images: [...request.images], item: { ...request.item, description: content } };
    }

    function CombineitemConverImageFiles(request: ICreateItemRequest): ICreateItemRequest {
        // 過濾掉已經存在的圖片
        const newItemConverImageFiles = itemConverImageFiles.filter(
            file => file.status !== 'done' && file.originFileObj
        );

        // 加入新的 ItemCover 圖片
        newItemConverImageFiles.forEach((file) => {
            if (file.originFileObj) {
                const extension = file.name.split('.').pop();
                const fileName = `${ImageUseageType.ItemCover}_${uuidv4()}.${extension}`;
                const newFile = new File([file.originFileObj], fileName, { type: file.type });
                request.images.push(newFile);
            }
        });

        return request;
    }

    const nextStepDisabledTooltip = i18nLanguage === 'zh_TW' ? '部分資料填寫未齊全' : 'Some required fields are incomplete';

    return (
        <div>
            <Steps style={{ padding: '30px' }} current={currentStep}>
                <Steps.Step title={translate('Create') + ' ' + translate('Item')} />
                <Steps.Step title={translate('Preview')} />
            </Steps>
            {currentStep === AddItemStep.FillInfo && (
                <>
                    <div>
                        <Flex style={{ margin: '30px' }} justify="center">
                            <BasicInformation
                                ref={basicInfoRef}
                                onIsAllValid={(isAllValid) => setIsBasicInfoValid(isAllValid)}
                            />
                        </Flex>
                    </div>
                    <Divider style={{ padding: '10px' }}></Divider>
                    <SpecsForm
                        previewOnly={false}
                        onIsAllValid={(isAllValid) => setIsSpecsAllValid(isAllValid)}
                    />
                    <Divider style={{ padding: '10px' }}></Divider>
                    <AlterDescription />
                    <Flex justify="center" gap="middle" style={{ padding: '30px' }}>
                        <Button
                            shape="round"
                            type="primary"
                            onClick={backToItemManage}>
                            {translate('Back')}
                        </Button>
                        <Tooltip title={!isBasicInfoValid || !isSpecsAllValid ? nextStepDisabledTooltip : ''}>
                            <Button
                                shape="round"
                                type="primary"
                                onClick={() => { setCurrentStep(AddItemStep.Preview) }}
                                disabled={!isBasicInfoValid || !isSpecsAllValid}
                            >
                                {translate('Next step')}
                            </Button>
                        </Tooltip>
                    </Flex>
                </>
            )}
            {currentStep === AddItemStep.Preview && (
                <div>
                    <PreviewItem />
                    <Flex justify="center" gap="middle" style={{ padding: '30px' }}>
                        <Button
                            shape="round"
                            type="primary"
                            onClick={() => { setCurrentStep(AddItemStep.FillInfo) }}
                        >
                            {translate('Previous step')}
                        </Button>
                        <Tooltip title={!isBasicInfoValid || !isSpecsAllValid ? nextStepDisabledTooltip : ''}>
                            <Button
                                shape="round"
                                type="primary"
                                onClick={() => { createItemAsync() }}
                                disabled={!isBasicInfoValid || !isSpecsAllValid}
                            >
                                {translate('Create')}
                            </Button>
                        </Tooltip>
                    </Flex>
                </div>
            )}
        </div>
    );
}
export default AddItemPage;
