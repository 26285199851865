import { FrontEndDisplayType } from "@/enums/Enums";
import { InvoiceOrderStatus, InvoviceOrderType, ThirdPartyInvoiceQueryStatusResponseType } from "@/enums/ThirdPartyInvoice";
import { IGetThirdPartyOrdersByPlatformOrderRequest, IQueryThirdPartyOrderStatusRequest } from "@/interfaces/Requests/Requests";
import { IPrintThirdPartyInvoiceRequest } from "@/interfaces/Requests/ThirdPartyInvoice";
import { IBackOfficeMerchantThirdPartyInvoiceSettingViewModel, IOrder } from "@/interfaces/Responses/Responses";
import { IThirdPartyInvoiceOrderViewModel } from "@/interfaces/Responses/ThirdPartyInvoice";
import { useGetBackOfficeMerchantThirdPartyInvoiceSettingViewModelsApi, useGetThirdPartyOrdersByPlatformOrderApi, usePrintInvoiceApi, useQueryInvoiceOrderStatusApi } from "@/lib/api/thirdPartyInvoices";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { ThirdPartyValuesContextProvider } from "@/lib/contexts/ThirdPartyValuesContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import LoadingComponent from "@/Templates/components/LoadingCompoent";
import { UrlHelper } from "@/Templates/lib/UrlHelper";
import { Button, Card, Descriptions, Empty, Flex, Form, Input, Modal, Select, Tag } from "antd";
import JSONBig from "json-bigint";
import React, { useContext, useEffect } from "react";
import { useMutation } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import InvoiceSelector, { IInvoiceSelectorRef } from "./InvoiceSelector";

export interface IInvoiceOrdersProps {
    order: IOrder;
}

export interface IInvoiceOrdersRef {
    onOpen: () => void;
}

const InvoiceOrders = React.forwardRef((props: IInvoiceOrdersProps, ref: React.ForwardedRef<IInvoiceOrdersRef | undefined>) => {
    const urlHelper = new UrlHelper();
    const { merchantId, messageApi, merchantPortalOptionSetting } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const navigate = useNavigate();
    const invoiceSelectorRef = React.useRef<IInvoiceSelectorRef>();
    const { data: invoiceOrdersFromApi, mutate: invoiceOrdersFromApiMutate, isLoading } = useMutation(async (reqeust: IGetThirdPartyOrdersByPlatformOrderRequest) => await useGetThirdPartyOrdersByPlatformOrderApi(reqeust));
    const [addinvoiceOrderVisible, setAddinvoiceOrderVisible] = React.useState(false);
    const { data: merchantThirdPartyInvoiceSettingViewModels, mutate: merchantThirdPartyInvoiceSettingViewModelsMutate } = useMutation(async () => await useGetBackOfficeMerchantThirdPartyInvoiceSettingViewModelsApi());
    const { mutate: queryOrderStatusMutate, isLoading: queryOrderStatusLoading } = useMutation(async (reqeust: IQueryThirdPartyOrderStatusRequest) => await useQueryInvoiceOrderStatusApi(reqeust), {
        onSuccess: (response, request) => {
            if (response.isSuccess) {
                messageApi.success(translate("Operation success"));
                switch (response.result?.type) {
                    case ThirdPartyInvoiceQueryStatusResponseType.Normal:
                        invoiceOrdersFromApiMutate({ orderId: props.order.id });
                        break;
                    case ThirdPartyInvoiceQueryStatusResponseType.Redirect:
                        //Redirect to data
                        navigate(response.result?.data);
                        break;
                }
            }
        }
    });
    const { mutate: printInvoiceMutate } = useMutation(async (reqeust: IPrintThirdPartyInvoiceRequest) => await usePrintInvoiceApi(reqeust), {
        onSuccess: (response, request) => {
            if (!response.isSuccess) {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
            const data = JSONBig.parse(response.result?.data || '{}');
            switch (response.result?.type) {
                case FrontEndDisplayType.PageFormPost:
                    urlHelper.pageFormPost(response.result?.url, data);
                    break;
                case FrontEndDisplayType.PageRedirectURL:
                    urlHelper.openRedirect(response.result?.url, data);
                    break;
                case FrontEndDisplayType.OpenNewPage:
                    urlHelper.openInPopup(response.result?.url, data);
                    break;
                default:
                    break;
            }
        }
    });
    const [selectedInvoiceProvider, setSelectedInvoiceProvider] = React.useState<IBackOfficeMerchantThirdPartyInvoiceSettingViewModel>();
    const getInvoiceOrderStatusColor = (status: string) => {
        switch (status) {
            case 'Created':
            case 'DuringShipping':
            case 'ArrivalWaitForPickup':
                return 'blue'; // Change color based on the status
            case 'Done':
                return 'green';
            default:
                return 'red';
        }
    };
    const handleCreateinvoiceOrder = (thirdPartySettingId: BigInt) => {
        setAddinvoiceOrderVisible(true);
    };
    const handlePrintInvoiceOrder = (invoiceOrder: IThirdPartyInvoiceOrderViewModel) => {
        printInvoiceMutate({
            thirdPartyOrderId: invoiceOrder.id,
            thirdPartySettingId: invoiceOrder.thirdPartySettingId
        });
    };
    const handleQueryinvoiceOrderStatus = (thirdPartyinvoiceOrderId: BigInt) => {
        var request: IQueryThirdPartyOrderStatusRequest = {
            thirdPartyOrderId: thirdPartyinvoiceOrderId
        };
        queryOrderStatusMutate(request);
    }
    const getEnabledInvoiceSettings = () => {
        return merchantThirdPartyInvoiceSettingViewModels?.result?.filter(item => item.isDisable === false) || [];
    };
    const sendOrder = (invoiceOrders: IThirdPartyInvoiceOrderViewModel[]) => {
        if (isLoading) {
            return <LoadingComponent></LoadingComponent>
        }
        //desc order by created date
        var invoiceOrder = invoiceOrders
            .filter(order => order.type === InvoviceOrderType.Normal)
            .sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime())[0];
        if (invoiceOrder) {
            return (
                <Card title={`${translate('Invoice')}`}>
                    <Descriptions column={1}>
                        <Descriptions.Item label="ID">{invoiceOrder.id.toString()}</Descriptions.Item>
                        <Descriptions.Item label="Provider Name">
                            {invoiceOrder.providerName.toString()}
                        </Descriptions.Item>
                        {
                            invoiceOrder.providerOrderId &&
                            <Descriptions.Item label="Provider Order ID">
                                {invoiceOrder.providerOrderId.toString()}
                            </Descriptions.Item>
                        }
                        {
                            invoiceOrder.values['InvoiceType'] &&
                            <Descriptions.Item label="Invoice Type">
                                {invoiceOrder.values['InvoiceType']}
                            </Descriptions.Item>
                        }
                        <Descriptions.Item label="Type">{invoiceOrder.type}</Descriptions.Item>
                        <Descriptions.Item label="Is Success Create From Provider">
                            <span style={{ color: invoiceOrder.isSuccessCreateFromProvider ? 'inherit' : 'red' }}>
                                {invoiceOrder.isSuccessCreateFromProvider.toString()}
                            </span>
                        </Descriptions.Item>
                        {
                            invoiceOrder.providerOrderId && (
                                <Descriptions.Item label="Provider Order ID">{invoiceOrder.providerOrderId}</Descriptions.Item>
                            )
                        }
                        <Descriptions.Item label="Status">
                            <Tag color={getInvoiceOrderStatusColor(invoiceOrder.status)}>
                                {invoiceOrder.status}
                            </Tag>
                        </Descriptions.Item>
                        {
                            invoiceOrder.status === InvoiceOrderStatus.Created &&
                            invoiceOrder.values['InvoiceType'] !== 'Donate' &&
                            <Descriptions.Item >
                                <Flex style={{ width: '100%' }} justify="center">
                                    <Button
                                        onClick={() => handlePrintInvoiceOrder(invoiceOrder)}>
                                        {translate('Print')}
                                    </Button>
                                </Flex>
                            </Descriptions.Item>
                        }
                    </Descriptions>
                    {
                        merchantPortalOptionSetting?.isEnableInovice === false &&
                        <Empty description={translate('The invoice module is not enabled.')}></Empty>
                        ||
                        !invoiceOrder.isSuccessCreateFromProvider &&
                        (
                            <Flex justify="center">
                                <Button
                                    type="primary"
                                    danger
                                    disabled={isLoading}
                                    onClick={() => handleCreateinvoiceOrder(invoiceOrder.thirdPartySettingId)}>
                                    {translate('Retry Genterate')}
                                </Button>
                            </Flex>
                        )
                    }
                    {
                        queryOrderStatusLoading &&
                        <LoadingComponent></LoadingComponent>
                        ||
                        invoiceOrder.isSuccessCreateFromProvider && invoiceOrder.status !== InvoiceOrderStatus.Created &&
                        (
                            <Flex justify="center">
                                <Button
                                    disabled={queryOrderStatusLoading}
                                    type="primary"
                                    onClick={() => handleQueryinvoiceOrderStatus(invoiceOrder.id)}>
                                    {translate('Query Status')}
                                </Button>
                            </Flex>
                        )
                    }
                </Card>
            );
        }
        else {
            return (
                <Card title={`${translate('Invoice')}`}>
                    {
                        getEnabledInvoiceSettings().length === 0 &&
                        <Empty description={
                            <>
                                {translate('No third party invoice provider is enabled.')}
                                <br />
                                <Link to={`/thirdParty?tab=invoiceSettingList`}>{translate('Click here to configure.')}</Link>
                            </>
                        }></Empty>
                    }
                    {
                        getEnabledInvoiceSettings().length > 0 &&
                        <Form >
                            <Flex justify="center">
                                <h3>{translate('Parameters')}</h3>
                            </Flex>
                            <Form.Item label={translate('Country')}>
                                <Select value={selectedInvoiceProvider?.supportedCountry}
                                    onChange={(value) => {
                                        setSelectedInvoiceProvider(getEnabledInvoiceSettings().find(item => item.supportedCountry === value));
                                    }}>
                                    {
                                        Array.from(new Set(
                                            getEnabledInvoiceSettings().map(
                                                item => item.supportedCountry
                                            )
                                        )).map((country, index) => (
                                            <Select.Option value={country} key={index}>
                                                {translate(`Country.${country}`)}
                                            </Select.Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                            {
                                Object.entries(props.order.inoviceValues)
                                    .filter(([key, value]) => value !== null && value !== undefined && value !== "")
                                    .map(([key, value], index) => {
                                        return (
                                            <Form.Item label={key} key={index}>
                                                <Input value={value} disabled />
                                            </Form.Item>
                                        );
                                    })
                            }
                            <Flex justify="center">
                                <Button type="primary" onClick={() => handleCreateinvoiceOrder(props.order.id)}>{translate('Create Invoice')}</Button>
                            </Flex>
                        </Form>
                    }
                </Card>
            );
        }
    };


    useEffect(() => {
        if (merchantId) {
            var request: IGetThirdPartyOrdersByPlatformOrderRequest = {
                orderId: props.order.id
            };
            invoiceOrdersFromApiMutate(request);
            merchantThirdPartyInvoiceSettingViewModelsMutate();
        }
    }, [merchantId]);

    return (
        <>
            {sendOrder(invoiceOrdersFromApi?.result || [])}
            <Modal
                open={addinvoiceOrderVisible}
                afterOpenChange={(isOpen) => {
                    if (isOpen)
                        (invoiceSelectorRef?.current?.onOpen());
                    else
                        (invoiceSelectorRef?.current?.onClose());
                }}
                onCancel={() => {
                    setAddinvoiceOrderVisible(false);
                }}
                footer={<></>}>
                <>
                    <ThirdPartyValuesContextProvider>
                        <InvoiceSelector
                            ref={invoiceSelectorRef}
                            order={props.order}
                            oninvoiceOrderCreated={() => {
                                var request: IGetThirdPartyOrdersByPlatformOrderRequest = {
                                    orderId: props.order.id
                                };
                                invoiceOrdersFromApiMutate(request);
                                merchantThirdPartyInvoiceSettingViewModelsMutate();
                                setAddinvoiceOrderVisible(false);
                            }}
                        >
                        </InvoiceSelector>
                    </ThirdPartyValuesContextProvider>
                </>
            </Modal>
        </>
    );
});

export default InvoiceOrders;
