import { IMerchant } from "@/interfaces/Responses/Responses";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { Button, Card, Col, Flex, Modal, Row, Select, Tag, Typography } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import MerchantInfomationPopover from "./MerchantInfomationPopover";
import MerchantUpgradeOrder, { IMerchantUpgradeOrderRef } from "./MerchantUpgradeOrder";

export interface IMerchantInfosProp {
    merchants: IMerchant[] | undefined;
}

const MerchantInfos: React.FC<IMerchantInfosProp> = ({ }) => {
    const { Title } = Typography;
    const {
        merchantId,
        setIsPageLoading,
        accessibleMerchants,
        setMerchantIdAggregate,
        isSetMerchantIdAggregateLoading,
        messageApi,
        portalLink,
        tryGetPortalLinkLoading,
        switchBackOfficeMerchant
    } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const merchantUpgradeOrderRef = useRef<IMerchantUpgradeOrderRef>();
    const [visibleForUpgrade, setVisibleForUpgrade] = useState(false);
    const safeMerchants = accessibleMerchants || [];
    const selectedMerchant = safeMerchants.find(x => x.id.toString() === merchantId?.toString());
    const handleChange = (value: string) => {
        setMerchantIdAggregate(BigInt(value));
    };
    const renderExpireTime = (expireTime: string) => {
        const isExpired = new Date(expireTime) < new Date();
        return (
            <span>
                {isExpired ? (
                    <>
                        <span style={{ color: 'red' }}>{expireTime}</span>
                        <Tag color="red">{translate('Expired')}</Tag>
                    </>
                ) : (
                    <span>{expireTime}</span>
                )}
            </span>
        );
    };
    useEffect(() => {
        if (merchantId === undefined && safeMerchants.length > 0) {
            setMerchantIdAggregate(safeMerchants[0].id);
            messageApi.warning(translate('auto switch to first own merchant'));
        }
    }, [merchantId, safeMerchants])

    if (safeMerchants.length == 0) {
        return;
    }
    return (
        <>
            <Row style={{ margin: '20px' }}>
                {safeMerchants.length > 0 && (
                    <Col span={24}>
                        <Flex justify="center" align="center">
                            <Title level={3}>{translate('Merchants')}</Title>
                        </Flex>
                        <Flex justify="center" align="center">
                            <MerchantInfomationPopover />
                            <Select
                                style={{ width: 200 }}
                                value={merchantId ? merchantId.toString() : null}
                                onChange={handleChange}
                                placeholder={translate('Select') + ' ' + translate('Merchant')}
                            >
                                {safeMerchants.map(merchant => (
                                    <Select.Option key={merchant.id.toString()} value={merchant.id.toString()}>
                                        {merchant.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Flex>
                    </Col>
                )}
                <Col style={{ marginTop: '30px' }} span={24}>
                    <Flex justify="center">
                        {selectedMerchant && (
                            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                <Card loading={isSetMerchantIdAggregateLoading}>
                                    <p>{translate('Merchant ID')}: {selectedMerchant.id.toString()}</p>
                                    <p>
                                        {portalLink && (
                                            <a href={portalLink} target="_blank" rel="noopener noreferrer">
                                                {translate('Visit portal website')}
                                            </a>
                                        )}
                                    </p>
                                    <p>
                                        {translate('Country')}: {translate(`Country.${selectedMerchant.country}`)}
                                    </p>
                                    <p>
                                        {translate('Website')}{translate('Level')}: {translate(`MerchantWebsiteUpgradeLevel.${selectedMerchant.merchantWebsiteUpgradeLevel}`)}
                                    </p>
                                    <p>
                                        {translate('Expire Time')}: {renderExpireTime(selectedMerchant.upgradeExpiredTime)}
                                    </p>
                                    <p>{translate('Create Date')}: {selectedMerchant.createDate}</p>
                                    <p>{translate('Banned')}: {selectedMerchant.isBanned ? translate('Yes') : translate('No')}</p>
                                    <Flex justify="center">
                                        <Button onClick={() => setVisibleForUpgrade(true)} disabled={selectedMerchant === undefined}>
                                            {translate('Subscribe')}
                                        </Button>
                                    </Flex>
                                    <Modal
                                        open={visibleForUpgrade}
                                        afterOpenChange={(open) => {
                                            if (open) {
                                                merchantUpgradeOrderRef.current?.refetch();
                                            }
                                        }}
                                        onCancel={() => {
                                            setVisibleForUpgrade(false);
                                            if (merchantUpgradeOrderRef.current) {
                                                merchantUpgradeOrderRef.current.resetSelectedLevel();
                                            }
                                        }}
                                        footer={null}
                                    >
                                        <MerchantUpgradeOrder
                                            merchant={selectedMerchant ? safeMerchants.find(m => m.id.toString() === selectedMerchant.id.toString()) : undefined}
                                            ref={merchantUpgradeOrderRef}
                                        />
                                    </Modal>
                                </Card>
                            </Col>
                        )}
                    </Flex>
                </Col>
            </Row>
        </>
    );
}

export default MerchantInfos;
