import { MerchantCheckoutType } from '@/Templates/enums/templateEnums';
import { IAlterMerchantPortalSettingRequest } from '@/interfaces/Requests/Requests';
import { useAlterMerchantBrowserTabIconApi, useAlterMerchantIconApi, useAlterMerchantPortalSettingApi } from '@/lib/api/merchants';
import { GlobalContext } from '@/lib/contexts/GlobalContext';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import { PlusOutlined, QuestionCircleTwoTone } from '@ant-design/icons';
import { Button, Col, Flex, Form, Input, InputNumber, Modal, Popover, Row, Select, Switch, Upload } from 'antd';
import Title from 'antd/es/typography/Title';
import { RcFile, UploadFile } from 'antd/es/upload/interface';
import React, { useContext, useEffect, useImperativeHandle, useState } from 'react';
import { useMutation } from 'react-query';
import { v4 as uuidv4 } from 'uuid';
import { PortalSettingPageContext } from '../Contexts/PortalSettingPageContext';

export interface IPortalGlobalSystemSettingProp { }
export interface IPortalGlobalSystemSettingRef {
    onRefresh: () => void;
}

const PortalGlobalSystemSetting = React.memo(
    React.forwardRef(
        (
            props: IPortalGlobalSystemSettingProp,
            ref: React.ForwardedRef<IPortalGlobalSystemSettingRef | undefined>
        ) => {
            const [form] = Form.useForm();
            const {
                merchantId,
                messageApi,
                merchantPortalOptionSetting,
                getMerchantPortalOptionSetting,
            } = useContext(GlobalContext);
            const { supportedLanguages, translate, i18nLanguage } = useContext(TranslationContext);
            const { abortController } = useContext(PortalSettingPageContext);
            const [imageChanged, setImageChanged] = useState(false);
            const [fileList, setFileList] = useState<UploadFile[]>([]);
            const [browserTabImageChanged, setBrowserTabImageChanged] = useState(false);
            const [browserTabFileList, setBrowserTabFileList] = useState<UploadFile[]>([]);
            const [alterRequest, setAlterRequest] = useState<IAlterMerchantPortalSettingRequest>({
                merchantName: merchantPortalOptionSetting?.merchantName,
                portalLanguage: merchantPortalOptionSetting?.portalLanguage,
                merchantCheckoutType: merchantPortalOptionSetting?.merchantCheckoutType,
                isEnableInovice: merchantPortalOptionSetting?.isEnableInovice,
                orderMinimumAmountLimit: merchantPortalOptionSetting?.orderMinimumAmountLimit,
                isClosed: merchantPortalOptionSetting?.isClosed,
                merchantId: merchantId,
            } as IAlterMerchantPortalSettingRequest);

            useEffect(() => {
                if (merchantPortalOptionSetting?.merchantIconPath) {
                    setFileList([
                        {
                            uid: uuidv4(),
                            name: 'merchantIcon',
                            status: 'done',
                            url: merchantPortalOptionSetting.merchantIconPath,
                        },
                    ]);
                } else {
                    setFileList([]);
                }

                if (merchantPortalOptionSetting?.merchantBrowserTabIconPath) {
                    setBrowserTabFileList([
                        {
                            uid: uuidv4(),
                            name: 'browserTabIcon',
                            status: 'done',
                            url: merchantPortalOptionSetting.merchantBrowserTabIconPath,
                        },
                    ]);
                } else {
                    setBrowserTabFileList([]);
                }
            }, [merchantPortalOptionSetting?.merchantIconPath, merchantPortalOptionSetting?.merchantBrowserTabIconPath]);

            const { mutateAsync: alterMerchantPortalSettingApiMutate, isLoading: alterIsloading } =
                useMutation(
                    async (request: IAlterMerchantPortalSettingRequest) =>
                        await useAlterMerchantPortalSettingApi(
                            request,
                            abortController.current?.signal
                        ),
                    {
                        onSuccess: (response) => {
                            if (response.isSuccess) {
                                messageApi.success(translate('Operation success'));
                            } else {
                                messageApi.error(translate(response.message || 'Operation failed'));
                            }
                        },
                    }
                );

            const { mutateAsync: useAlterMerchantIconApiMutate, isLoading: alterIconIsloading } =
                useMutation(
                    async (request: FormData) =>
                        await useAlterMerchantIconApi(request, abortController.current?.signal),
                    {
                        onSuccess: (response) => {
                            if (!response.isSuccess) {
                                messageApi.error(translate(response.message || 'Operation failed'));
                            }
                        },
                    }
                );

            const { mutateAsync: useAlterMerchantBrowserTabIconApiMutate, isLoading: alterBrowserTabIconIsloading } =
                useMutation(
                    async (request: FormData) =>
                        await useAlterMerchantBrowserTabIconApi(request, abortController.current?.signal),
                    {
                        onSuccess: (response) => {
                            if (!response.isSuccess) {
                                messageApi.error(translate(response.message || 'Operation failed'));
                            }
                        },
                    }
                );


            useEffect(() => {
                if (merchantPortalOptionSetting) {
                    setAlterRequest({
                        merchantName: merchantPortalOptionSetting.merchantName,
                        portalLanguage: merchantPortalOptionSetting.portalLanguage,
                        merchantCheckoutType: merchantPortalOptionSetting.merchantCheckoutType,
                        isEnableInovice: merchantPortalOptionSetting.isEnableInovice,
                        orderMinimumAmountLimit: merchantPortalOptionSetting.orderMinimumAmountLimit,
                        isClosed: merchantPortalOptionSetting.isClosed,
                    });
                    form.setFieldsValue({
                        merchantName: merchantPortalOptionSetting.merchantName,
                        portalLanguage: merchantPortalOptionSetting.portalLanguage,
                        merchantCheckoutType: merchantPortalOptionSetting.merchantCheckoutType,
                        isEnableInovice: merchantPortalOptionSetting.isEnableInovice,
                        orderMinimumAmountLimit: merchantPortalOptionSetting.orderMinimumAmountLimit,
                        isClosed: merchantPortalOptionSetting.isClosed,
                    });
                }
            }, [merchantPortalOptionSetting, merchantId]);

            useEffect(() => {
                form.setFieldsValue({
                    merchantName: alterRequest.merchantName,
                    portalLanguage: alterRequest.portalLanguage,
                    merchantCheckoutType: alterRequest.merchantCheckoutType,
                    isEnableInovice: alterRequest.isEnableInovice,
                    orderMinimumAmountLimit: alterRequest.orderMinimumAmountLimit,
                });
            }, [alterRequest, form]);

            useImperativeHandle(ref, () => ({
                onRefresh() {
                    getMerchantPortalOptionSetting.mutate({ merchantId: merchantId! });
                },
            }));

            const handleBeforeUpload = (file: RcFile) => {
                const newFile: UploadFile = {
                    uid: uuidv4(),
                    name: file.name,
                    status: 'done',
                    url: URL.createObjectURL(file),
                    originFileObj: file,
                };
                setFileList([newFile]);
                setImageChanged(true);
                return false; // Prevent auto-upload
            };

            const handleRemove = () => {
                setFileList([]);
                setImageChanged(true);
            };

            const handleBrowserTabBeforeUpload = (file: RcFile) => {
                const newFile: UploadFile = {
                    uid: uuidv4(),
                    name: file.name,
                    status: 'done',
                    url: URL.createObjectURL(file),
                    originFileObj: file,
                };
                setBrowserTabFileList([newFile]);
                setBrowserTabImageChanged(true);
                return false;
            };

            const handleBrowserTabRemove = () => {
                setBrowserTabFileList([]);
                setBrowserTabImageChanged(true);
            };

            const handleSave = async () => {
                if (alterRequest.isClosed !== merchantPortalOptionSetting?.isClosed && alterRequest.isClosed === true) {
                    const confirmed = await new Promise((resolve) => {
                        Modal.confirm({
                            title: translate('Warning'),
                            content: i18nLanguage === 'zh_TW'
                                ? '關閉商戶將導致用戶無法訪問您的商店。確定要繼續嗎？'
                                : 'Closing the merchant will prevent users from accessing your store. Are you sure you want to continue?',
                            okText: translate('Yes'),
                            cancelText: translate('No'),
                            onOk: () => resolve(true),
                            onCancel: () => resolve(false)
                        });
                    });

                    if (!confirmed) {
                        setAlterRequest({
                            ...alterRequest,
                            isClosed: merchantPortalOptionSetting?.isClosed ?? false
                        });
                        form.setFieldsValue({
                            isClosed: merchantPortalOptionSetting?.isClosed ?? false
                        });
                        return;
                    }
                }

                const iconUploadPromise =
                    imageChanged && fileList.length > 0 && fileList[0].originFileObj
                        ? new Promise<void>(async (resolve) => {
                            const formData = new FormData();
                            formData.append(
                                'Image',
                                fileList[0].originFileObj as Blob,
                                fileList[0].name
                            );
                            await useAlterMerchantIconApiMutate(formData);
                            resolve();
                        })
                        : Promise.resolve();

                const browserTabIconUploadPromise = browserTabImageChanged && browserTabFileList.length > 0 && browserTabFileList[0].originFileObj
                    ? new Promise<void>(async (resolve) => {
                        const formData = new FormData();
                        formData.append('Image', browserTabFileList[0].originFileObj as Blob, browserTabFileList[0].name);
                        await useAlterMerchantBrowserTabIconApiMutate(formData);
                        resolve();
                    })
                    : Promise.resolve();

                await Promise.all([
                    iconUploadPromise,
                    browserTabIconUploadPromise,
                    alterMerchantPortalSettingApiMutate(alterRequest)
                ]);

                getMerchantPortalOptionSetting.mutate({ merchantId: merchantId! });
                setImageChanged(false);
                setBrowserTabImageChanged(false);
            };

            const MinimumAmountLimitPopover = () => {
                const content =
                    i18nLanguage === 'zh_TW'
                        ? '此欄位功能為買家需要達到最低金額才能下單'
                        : 'This field sets the Minimum order amount required for buyers to place an order';

                return (
                    <Popover content={content} title={translate('Minimum order amount')}>
                        <QuestionCircleTwoTone style={{ marginLeft: 8 }} />
                    </Popover>
                );
            };

            return (
                merchantPortalOptionSetting && (
                    <>
                        <Row>
                            <Col xs={{ span: 24 }} style={{ marginTop: '30px', marginBottom: '30px' }}>
                                <div style={{ textAlign: 'center' }}>
                                    <Title level={5}>{translate('Merchant logo')}</Title>
                                    <Upload
                                        className='merchant-logo-upload'
                                        key={merchantPortalOptionSetting.merchantIconPath || 'upload'}
                                        listType="picture-card"
                                        beforeUpload={handleBeforeUpload}
                                        showUploadList={false}
                                        onRemove={handleRemove}
                                    >
                                        {fileList.length >= 1 ? (
                                            <img
                                                src={fileList[0].url || fileList[0].thumbUrl}
                                                alt="merchantIcon"
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'contain'
                                                }}
                                            />
                                        ) : (
                                            <div>
                                                <PlusOutlined />
                                                <div style={{ marginTop: 8 }}>{translate('Upload')}</div>
                                            </div>
                                        )}
                                    </Upload>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{ span: 24 }} style={{ marginTop: '30px', marginBottom: '30px' }}>
                                <div style={{ textAlign: 'center' }}>
                                    <Title level={5}>{translate('Browser Tab Icon')}</Title>
                                    <Upload
                                        className='browser-tab-icon-upload'
                                        key={merchantPortalOptionSetting.merchantBrowserTabIconPath || 'browser-tab-upload'}
                                        listType="picture-card"
                                        beforeUpload={handleBrowserTabBeforeUpload}
                                        showUploadList={false}
                                        onRemove={handleBrowserTabRemove}
                                    >
                                        {browserTabFileList.length >= 1 ? (
                                            <img
                                                src={browserTabFileList[0].url || browserTabFileList[0].thumbUrl}
                                                alt="browserTabIcon"
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'contain'
                                                }}
                                            />
                                        ) : (
                                            <div>
                                                <PlusOutlined />
                                                <div style={{ marginTop: 8 }}>{translate('Upload')}</div>
                                            </div>
                                        )}
                                    </Upload>
                                </div>
                            </Col>
                        </Row>
                        <Flex justify='center' style={{ width: '100%' }}>
                            <Row>
                                <Col xs={{ span: 24 }} style={{ marginTop: '30px', marginBottom: '30px' }}>
                                    <div style={{ textAlign: 'center' }}>
                                        <Form form={form} layout="vertical">
                                            <Form.Item
                                                name="merchantName"
                                                label={`${translate('Merchant')} ${translate('Name')}`}
                                            >
                                                <Input
                                                    onChange={(e) =>
                                                        setAlterRequest({
                                                            ...alterRequest,
                                                            merchantName: e.target.value,
                                                        })
                                                    }
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                name="isClosed"
                                                label={translate('Merchant portal status')}
                                                valuePropName="checked"
                                                initialValue={false}
                                            >
                                                <Switch
                                                    checkedChildren={translate('Closed')}
                                                    unCheckedChildren={translate('Open')}
                                                    style={{
                                                        backgroundColor: alterRequest.isClosed ? '#ff4d4f' : '#52c41a'
                                                    }}
                                                    onChange={(checked: boolean) => {
                                                        setAlterRequest({
                                                            ...alterRequest,
                                                            isClosed: checked,
                                                        });
                                                    }}
                                                />
                                            </Form.Item>
                                            <Form.Item name="portalLanguage" label={translate('Portal default language')}>
                                                <Select
                                                    onChange={(value) =>
                                                        setAlterRequest({
                                                            ...alterRequest,
                                                            portalLanguage: value,
                                                        })
                                                    }
                                                >
                                                    {supportedLanguages.map((language) => (
                                                        <Select.Option key={language} value={language}>
                                                            {translate(language)}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                            <Form.Item name="merchantCheckoutType" label={translate('Portal checkout type')}>
                                                <Select
                                                    onChange={(value) =>
                                                        setAlterRequest({
                                                            ...alterRequest,
                                                            merchantCheckoutType: value,
                                                        })
                                                    }
                                                >
                                                    {Object.keys(MerchantCheckoutType).map((key) => (
                                                        <Select.Option key={key} value={key}>
                                                            {translate(key)}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                name="orderMinimumAmountLimit"
                                                label={
                                                    <span>
                                                        {translate('Minimum order amount')}
                                                        <MinimumAmountLimitPopover />
                                                    </span>
                                                }
                                            >
                                                <InputNumber
                                                    min={0}
                                                    onChange={(value) =>
                                                        setAlterRequest({
                                                            ...alterRequest,
                                                            orderMinimumAmountLimit: value || 0,
                                                        })
                                                    }
                                                />
                                            </Form.Item>
                                        </Form>
                                    </div>
                                </Col>
                            </Row>
                        </Flex>
                        <Row>
                            <Col xs={{ span: 24 }}>
                                <div style={{ textAlign: 'center' }}>
                                    <Button
                                        loading={alterIsloading || alterIconIsloading}
                                        type="primary"
                                        onClick={handleSave}
                                    >
                                        {translate('Save')}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </>
                )
            );
        }
    )
);

export default PortalGlobalSystemSetting;
